import React from 'react'

export const handleStringArray = (content: string | string[]) => {
  if (typeof content === 'object') {
    return (
      <React.Fragment>
        {content.map((item, index: number) => (
          <div key={index}>{item}</div>
        ))}
      </React.Fragment>
    )
  } else if (typeof content === 'string') {
    return content
  } else {
    return content
  }
}
