import { createSlice } from '@reduxjs/toolkit'

interface IUrlItem {
  streamUrl: string
  type: number
  delay: number
}
export interface commonStateType {
  userAgentType: string
  currentLayout: string
  isDesktopM: boolean
  isDesktopMiddle: boolean
  isPortrait: boolean
  showBackdrop: boolean
  fixedPlayerData: {
    liveUrlInfo: IUrlItem[]
    roomId: string
    hostMemberInfoId: string
    coverPhotoUrl: string
    liveSourceType?: number
    liveSource?: string
  }
  showLoading: boolean
  previousPage?: string | null
  timerIds?: number[]
  locationData: {
    _dittoCountryCode?: string
    _dittoCountryName?: string
    _dittoContinentName?: string
    _dittoCurrency?: string
    _dittoLocale?: string
    timeZone?: string
  }
  isOverseaUser: boolean
}

const initialState: commonStateType = {
  userAgentType: '',
  currentLayout: '',
  isDesktopM: false, // width 在 1024 - 1200
  isDesktopMiddle: false,
  isPortrait: true,
  showBackdrop: false,
  fixedPlayerData: {
    liveUrlInfo: [],
    roomId: '',
    hostMemberInfoId: '',
    coverPhotoUrl: '',
  },
  showLoading: false,
  previousPage: null,
  timerIds: [],
  locationData: {},
  isOverseaUser: true, // countryCode
}

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setUserAgentType: (state, { payload }) => {
      state.userAgentType = payload.userAgentType
    },
    setCurrentLayout: (state, { payload }) => {
      state.currentLayout = payload.currentLayout
      state.isDesktopM = payload.isDesktopM
      state.isDesktopMiddle = payload.isDesktopMiddle
      state.isPortrait = payload.isPortrait
    },
    showBackdrop: (state, { payload }) => {
      state.showBackdrop = payload
    },
    setFixedPlayerData: (state, { payload }) => {
      state.fixedPlayerData = { ...state.fixedPlayerData, ...payload.fixedPlayerData }
    },
    resetFixedPlayerData: (state) => {
      state.fixedPlayerData = initialState.fixedPlayerData
    },
    setShowLoading: (state, { payload }) => {
      state.showLoading = payload.showLoading
    },
    setPreviousPage: (state, { payload }) => {
      state.previousPage = payload.currentPage
    },
    setDelayTimer: (state, { payload }) => {
      state.timerIds = state.timerIds?.concat(payload.timerId)
    },
    resetDelayTimer: (state) => {
      state.timerIds = []
    },
    setLocationData: (state, { payload }) => {
      state.locationData = payload.locationData
    },
    setIsOverseaUser: (state, { payload }) => {
      state.isOverseaUser = payload.isOverseaUser
    },
  },
})

export const {
  setUserAgentType,
  setCurrentLayout,
  showBackdrop,
  setFixedPlayerData,
  resetFixedPlayerData,
  setShowLoading,
  setPreviousPage,
  setDelayTimer,
  resetDelayTimer,
  setLocationData,
  setIsOverseaUser,
} = commonSlice.actions

export default commonSlice.reducer
