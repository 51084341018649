export const COUNTRY_CODE = {
  TW: 'TW',
  HK: 'HK',
  US: 'US',
  TR: 'TR',
  MO: 'MO',
  KR: 'KR',
  ID: 'ID',
  AU: 'AU',
}
