export const STORAGE_DATA = {
  TOKEN: 'token', // local
  IS_TIMEOUT: 'isTimeout', // local
  SHOPPINGCART_ID: 'shoppintcartId', // local
  SHOPPINGCART_CACHE_DATA: 'shoppingcartCacheData', // local
  LOGISTICS_CACHE_DATA: 'logisticsCacheData', // local
  SEARCH_HISTORY: 'serachHistory', // local
  FAST_CHECKOUT_CACHE_DATA: 'fastCheckoutCacheData', // session
  FIXED_PLAYER_CACHE_DATA: 'fixedPlayerCacheData', // session
  LOCATION_DATA: 'site_locationData', // session
  '2024_Chinese_New_Year_Lottery_Event': '2024ChineseNewYearLotteryEvent', // local
  GA_EVENT_PURCHASE_CACHE_DATA: 'gaEventPurchaseCacheData', // session
}
