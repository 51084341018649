import type { AppProps } from 'next/app'
import Head from 'next/head'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
// import { Roboto, Noto_Sans_TC, DM_Sans } from 'next/font/google'
import { appWithTranslation } from 'next-i18next'

// Libs
import { store } from '@/redux/store'

// Components
import App from '@/components/pages/App'

// Styles
import '@/styles/main.scss'
import '@/styles/Icon/index.scss'
import '@/styles/motion/index.scss'
import 'react-modern-drawer/dist/index.css'
import 'react-toastify/dist/ReactToastify.css'
import 'swiper/css'
import 'swiper/css/pagination'

const clientConfigs = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      retry: (failureCount: any, error: any) => {
        if (error.status === 401 && error.name !== 'FETCH_REFRESH_TOKEN' && error.name !== 'FETCH_USER_VERIFY') return true

        return false
      },
    },
  },
}

const queryClient = new QueryClient(clientConfigs)

// TODO: 照理要用 next/font，但目前 next/font 跟 inline-react-svg 的 babel 設定有衝突，要再研究 */
// 目前用 _document link 的方式
// const roboto = Roboto({ subsets: ['latin'], weight: ['100', '300', '400', '500', '700', '900'] })
// const notoSansTC = Noto_Sans_TC({ subsets: ['latin'], weight: ['100', '300', '400', '500', '700', '900'] })
// const dMSans = DM_Sans({ subsets: ['latin'], weight: ['400', '500', '700'] })

// export default function App(props) {
const MyApp = (props: AppProps) => {
  return (
    // <main className={`${roboto.className} ${notoSansTC.className} ${dMSans.className}`}>
    <>
      <Head>
        <title>TutorABC Shop</title>
        <meta name='title' content='TutorABC Shop' />
        <link rel='shortcut icon' href='/favicon.ico' />
        <meta property='og:title' key='title' content='TutorABC Shop' />
        <meta property='og:type' key='type' content='website' />
        <meta property='og:site_name' content='TutorABC Shop' />
        <meta property='fb:pages' content='102837472875865' />
        <meta name='viewport' content='width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no' />
        <meta data-rh='true' name='robots' content='index,follow,max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
      </Head>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <App {...props} />
        </QueryClientProvider>
      </Provider>
    </>
  )
}

export default appWithTranslation(MyApp)
