import { createSlice } from '@reduxjs/toolkit'

// Constant
import { CALENDAR_DEFAULT_PAGE_SIZE } from '@/constants/calendar'

export interface IInitialState {
  pageNo: number
  viewCategoryId: string
  viewDate: string
  pageSize: number
  scrollY: number | null
  calendarPathName: string
}

const initialState: IInitialState = {
  pageNo: 1,
  viewCategoryId: '',
  viewDate: '',
  pageSize: CALENDAR_DEFAULT_PAGE_SIZE,
  scrollY: null,
  calendarPathName: '',
}

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    setPreviousView: (state, { payload }) => {
      const { pageNo, viewCategoryId, viewDate } = payload

      state.pageNo = pageNo
      state.viewCategoryId = viewCategoryId
      state.viewDate = viewDate
    },
    setPageSize: (state, { payload }) => {
      const { pageSize } = payload

      state.pageSize = pageSize
    },
    setAnchor: (state, { payload }) => {
      const { scrollY } = payload

      state.scrollY = scrollY
    },
    setCalendarPathName: (state, { payload }) => {
      const { pathname } = payload

      state.calendarPathName = pathname
    },
    resetCalendarData: (state, { payload }) => {
      return {
        ...initialState,
        ...payload,
      }
    },
  },
})

export const { setPreviousView, setAnchor, setPageSize, resetCalendarData, setCalendarPathName } = calendarSlice.actions

export default calendarSlice.reducer
