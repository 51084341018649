export const ERROR_CODE = {
  TOKEN_EXPIRED: -10,
  RECORDING_ERROR: 15004,
  OUT_OF_STOCK: 6000,
  CLAIM_COUPON_CODE_OTHER_ERROR: 4001,
  CLAIM_COUPON_CODE_QUOTA_ZERO: 4002,
  CLAIM_COUPON_CODE_EXPIRED: 4003,
  CLAIM_COUPON_CODE_CLAIMED: 4004,
  LIVE_ROOM_CLOSED: 16001,
}
