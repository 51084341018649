import { useEffect } from 'react'
import Lottie from 'lottie-web'

// Components

// Styles

// Variables

type RendererSettingsType = {
  preserveAspectRatio?: string
  clearCanvas?: boolean
  hideOnTransparent?: boolean
}

type ConfigType = {
  loop?: boolean
  autoplay?: boolean
  rendererSettings?: RendererSettingsType
}

type LottieType = {
  lottieRef: any
  animationData: any
  config?: ConfigType
}

function LottieComponent(props: LottieType) {
  const { lottieRef, animationData, config } = props

  useEffect(() => {
    const animation: any = Lottie.loadAnimation({
      container: lottieRef.current,
      renderer: 'svg',
      animationData,
      ...config,
    })

    return () => {
      animation.destroy()
    }
  }, [animationData])

  return <></>
}

export default LottieComponent
