import toCamelCase from './to-camel-case'
import toSnakeCase from './to-snake-case'
import toSpaceCase from './to-space-case'
import toUpperCase from './to-upper-case'
import toUpperCamelCase from './to-upper-camel-case'

const to = {
  camel: toCamelCase,
  snake: toSnakeCase,
  space: toSpaceCase,
  upper: toUpperCase,
  upper_camel: toUpperCamelCase,
}

const CASES = {
  CAMEL: 'camel',
  SNAKE: 'snake',
  SPACE: 'space',
  UPPER: 'upper',
  UPPER_CAMEL: 'upper_camel',
}

export { toCamelCase, toSnakeCase, toSpaceCase, toUpperCase, toUpperCamelCase, CASES }
export default to
