import React from 'react'
import classnames from 'classnames/bind'
import { useTranslation } from 'next-i18next'

// Styles
import styles from './style.module.scss'

// Components

// Libs
import useSearchHistory from '@/hooks/useSearchHistory'
import { useRouter } from 'next/router'
import handleRoutePushWithQuery from '@/methods/url/handleRoutePushWithQuery'

// Constants
import { ROUTE_PATH } from '@/constants/routePath'
import { SEARCH_TYPE } from '@/constants/search'

const cx = classnames.bind(styles)
interface IHotSearchKeywordList {
  displayAmount: number
  className?: string
  title?: string
  isHistory?: boolean
  data: string[]
}

const HotSearchKeywordList = ({ displayAmount, className, title, data = [] }: IHotSearchKeywordList) => {
  const { t } = useTranslation()
  const { setHistoryLocalStorage } = useSearchHistory()
  const router = useRouter()
  const handleClick = (keyword: string) => {
    setHistoryLocalStorage(keyword)
    handleRoutePushWithQuery({
      router,
      pathname: ROUTE_PATH.SEARCH,
      query: { keyword: keyword, type: SEARCH_TYPE.ALL },
    })
  }

  return (
    <div className={cx('keyword-list', className)}>
      <div className={cx('keyword-list__heading')}>
        <p className={cx('keyword-list__heading-title')}>{title ? title : t('Search_Hot_Search_Keyword')}</p>
      </div>
      <div className={cx('keyword-list__content')}>
        {data?.slice(0, displayAmount).map((item, index) => {
          return (
            <div onClick={() => handleClick(item)} className={cx('keyword-list__content-item')} key={index}>
              <p className={cx('keyword-list__content-item-text')}>{item}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}
export default HotSearchKeywordList
