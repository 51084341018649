import React, { useRef } from 'react'
import classnames from 'classnames/bind'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'

// Libs
import { handleGtmClickLiveRoom } from '@/methods/handleGtmEvent'
import { GtmClickLiveRoomLiveStatus, GtmClickLiveRoomPosition } from '@/constants/gtmEvent'
import { goToLiveRoom } from '@/methods/goToLiveRoom'

// Components
import Lottie from '@/components/common/Lottie'

// Constants

// Styles
import styles from './style.module.scss'

// Lotties
import signalData from '@/assets/lotties/white-signal.json'

const cx = classnames.bind(styles)

interface Insight {
  className?: string
  status: string
  count?: number
  theme: string
  zIndex?: string
  position?: string | undefined
  top?: string
  right?: string
  bottom?: string
  left?: string
  isCircle?: boolean
  roomId?: string
  liveType?: number
  liveSourceType?: number
  handleInsightClick?: (event: any) => void
  gtmClickLiveRoomPosition?: GtmClickLiveRoomPosition
}

const Insight = (props: Insight) => {
  const {
    className,
    count,
    status,
    theme,
    zIndex,
    position,
    top,
    right,
    bottom,
    left,
    isCircle,
    roomId,
    gtmClickLiveRoomPosition,
    liveType,
    liveSourceType,
  }: any = props

  const { t } = useTranslation()

  const insightRef = useRef<any>(null)

  const router = useRouter()

  const canClick = !!roomId

  const getName = (status: string) => {
    switch (status) {
      case 'view':
        return 'Insight_View'
      case 'live':
        return 'Insight_Live'
      case 'empty':
        return ''
      default:
        return '-'
    }
  }

  const getIcon = (status: string) => {
    switch (status) {
      case 'view':
      case 'live':
      default:
        return (
          <div ref={insightRef} className={cx('insight__header-icon-lottie-wrapper')}>
            <Lottie lottieRef={insightRef} animationData={signalData} config={{ loop: true, autoplay: true }} />
          </div>
        )
    }
  }

  const handleInsightClick = (event: any) => {
    if (!canClick) return
    event.stopPropagation()
    goToLiveRoom({ router, roomId, liveType, liveSourceType })
    gtmClickLiveRoomPosition &&
      handleGtmClickLiveRoom({
        roomId,
        liveStatus: GtmClickLiveRoomLiveStatus.LIVE,
        position: gtmClickLiveRoomPosition,
      })
  }

  return (
    <div
      className={cx('insight', { canClick, isCircle, 'insight--empty': status === 'empty' }, className)} // status === 'empty'
      data-theme={theme}
      onClick={handleInsightClick}
      style={{
        zIndex,
        position,
        top,
        right,
        bottom,
        left,
      }}
    >
      <div className={cx('insight__header')}>
        {status !== 'empty' && <div className={cx('insight__header-title')}>LIVE</div>}
        <div className={cx('insight__header-icon', className)}>{getIcon(status)}</div>
      </div>
      {status === 'view' && (
        <div className={cx('insight__content')}>
          {count !== undefined && count !== null && <div className={cx('insight__count')}>{count}</div>}
          {!!status && status !== 'empty' && <div className={cx('insight__status')}>{t(getName(status))}</div>}
        </div>
      )}
    </div>
  )
}

export default Insight
