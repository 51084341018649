import classnames from 'classnames/bind'

// Components

// Constants

// Styles
import styles from './style.module.scss'

// Variables
const cx = classnames.bind(styles)

const Backdrop = () => {
  return (
    <div className={cx('backdrop')}>
      <div className={cx('backdrop__content')}>
        <div className={cx('backdrop__content-middle')}>
          <div className={cx('backdrop__left-circle')}></div>
          <div className={cx('backdrop__right-circle')}></div>
        </div>
      </div>
    </div>
  )
}

export default Backdrop
