//import { useAppDispatch } from '@/redux/hooks'
import { setLog } from '@/redux/reducers/log'
import { getParameterByName } from '@/utils/url'

const isError = (obj) => {
  //return Object.prototype.toString.call(obj) === '[object Error]'
  return obj && obj.stack && obj.message
}

const replacer = (key, value) => {
  if (isError(value)) {
    return value.message
  }

  return value
}

const serializer = (replacer, cycleReplacer) => {
  let stack = [],
    keys = []

  if (cycleReplacer == null)
    cycleReplacer = function (key, value) {
      if (stack[0] === value) return '[Circular ~]'
      return '[Circular ~.' + keys.slice(0, stack.indexOf(value)).join('.') + ']'
    }

  return function (key, value) {
    if (stack.length > 0) {
      let thisPos = stack.indexOf(this)
      ~thisPos ? stack.splice(thisPos + 1) : stack.push(this)
      ~thisPos ? keys.splice(thisPos, Infinity, key) : keys.push(key)
      if (~stack.indexOf(value)) value = cycleReplacer.call(this, key, value)
    } else stack.push(value)

    return replacer == null ? value : replacer.call(this, key, value)
  }
}

const stringifySafeV2 = (obj, replacer, spaces, cycleReplacer) => {
  return JSON.stringify(obj, serializer(replacer, cycleReplacer), spaces)
}

/*
//一些特殊物件 Object.entries 會轉不出來 ex:RTCIceCandidate物件轉出來是空的
const stringifySafeV1 = (obj, depth = 3, indent = 2) => {
  const seen = new WeakSet()

  function _stringify(obj, currentDepth) {
    if (currentDepth > depth) {
      return '...'
    }

    if (isError(obj)) {
      return obj.message
    }

    if (typeof obj === 'object' && obj !== null) {
      if (seen.has(obj)) {
        return '[Circular]'
      }
      seen.add(obj)

      const entries = Object.entries(obj)
      const result = entries.map(([key, value]) => {
        const indentation = ' '.repeat(indent * (currentDepth + 1))
        return `${indentation}"${key}": ${_stringify(value, currentDepth + 1)}`
      })

      return `{\n${result.join(',\n')}\n${' '.repeat(indent * currentDepth)}}`
    }

    return JSON.stringify(obj)
  }

  return _stringify(obj, 0)
}
*/

const checkArg = (arg) => {
  return arg !== undefined && arg.length > 0 && arg[0] === 'shop'
}

const asyncLog = async (dispatch, args, level) => {
  checkArg(args) && dispatch(setLog({ ts: Date.now(), level: level, data: stringifySafeV2(args, replacer) }))
}

// FIXME: 正常不該直接改原生的 console.log
// 但因為資料要塞到 redux，需要用到 dispatch，如果不改 console.log 就要各個地方各自實現
// 目前這版先以覆蓋 console.log 快速上版，之後再看有沒有更好的方式
export const checkLogMode = (dispatch) => {
  let testMode = getParameterByName('testMode')
  if (testMode !== '1') return

  console.log('console.log & err rw!!!')

  console.log = (function (oriLogFunc) {
    return function () {
      try {
        asyncLog(dispatch, arguments, 'LOG')
        oriLogFunc.call(console, ...arguments)
      } catch (e) {
        console.debug('console.log error', e)
      }
    }
  })(console.log)

  console.error = (function (oriLogFunc) {
    return function () {
      try {
        asyncLog(dispatch, arguments, 'ERROR')
        oriLogFunc.call(console, ...arguments)
      } catch (e) {
        console.debug('console.error error', e)
      }
    }
  })(console.error)
}
