// Role for live room

export const ROLE = {
  HOST: 20, // 直播主
  AUDIENCE: 11, // 已登入買家
  GUEST: 90, // 未登入買家
  IT: 30, //
}

export const ROLE_LABEL = {
  HOST: 'HOST', // 直播主
  AUDIENCE: 'AUDIENCE', // 已登入買家
  GUEST: 'GUEST', // 未登入買家
  TI: 'IT',
}
