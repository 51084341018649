import { isEmpty, cloneDeep } from 'lodash'
import getSearchParams from '@/methods/url/getSearchParams'

type ParamsType = {
  removeKeyList: string[]
  url?: string
  isLowerCase?: boolean
}

const removeSearchParams = ({ removeKeyList = [], url, isLowerCase = true }: ParamsType) => {
  if (!process.browser) return

  const originalSearchParams = getSearchParams()
  const newOriginalSearchParams = Object.entries(originalSearchParams as any).map(([key]) => key.toLowerCase())

  const urlObject = url ? new URL(url) : window.location
  const search = urlObject.search
  const searchParams = new URLSearchParams(search)

  // 統一把 search 的 key 轉成小寫，value 則保留原樣
  const newSearchParamsArray = Object.entries(Object.fromEntries(searchParams))
    .map(([key, value]) => {
      const isExistInOriginal = newOriginalSearchParams.indexOf(key.toLowerCase()) !== -1

      if (isExistInOriginal) return []

      return [[isLowerCase ? key.toLowerCase() : key], value]
    })
    .filter((item) => !isEmpty(item))

  const newSearchParams = { ...Object.fromEntries(newSearchParamsArray), ...originalSearchParams }

  const cloneSearchParams = cloneDeep(newSearchParams)

  removeKeyList.forEach((item) => {
    delete cloneSearchParams[item]
    delete cloneSearchParams[item.toLowerCase()]
  })

  const finalQuery = Object.entries(cloneSearchParams)
    .map(([key, value]) => `${key}=${encodeURIComponent(value as any)}`)
    .join('&')

  if (finalQuery) {
    return `${urlObject.origin}${urlObject.pathname}?${finalQuery}`
  } else {
    return `${urlObject.origin}${urlObject.pathname}`
  }
}

export default removeSearchParams
