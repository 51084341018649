// Libs
import { handleMessageEmit } from '@/methods/message/handleMessageEmit'
// import { updateHandUpStatus } from '@/redux/reducers/room'

// Constants
import { HAND_UP_STATUS, WS_EVENT_LIVE_SHOW } from '@/constants/socketEvent'

export interface RoomHandUpParams {
  socket: any
  dispatch?: any
  isConnect: boolean
  uid: string
  name: string
}

export const handleUserConnectStatus = ({ socket, uid, isConnect, name }: RoomHandUpParams) => {
  const extra = {
    name,
  }

  const content = {
    sid: WS_EVENT_LIVE_SHOW.sid,
    ssid: WS_EVENT_LIVE_SHOW.connect,
    body: {
      uid,
      status: isConnect ? HAND_UP_STATUS.ON : HAND_UP_STATUS.OFF,
      extra: JSON.stringify(extra),
    },
  }

  handleMessageEmit({ socket, content })
}
