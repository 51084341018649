import Service, { getCallApiFunction } from '../service'
import denormalizer from './denormalizer'
// import normalizer from './normalizer'

type ParamsType = {
  accountId: string
  name: string
}

class FetchStrawberryTokenApi extends Service {
  constructor(params: ParamsType) {
    super()
    this.name = 'FETCH_STRAWBERRY_TOKEN'

    this.config = {
      url: `/v1/user-auth/strawberry`,
      method: 'GET',
      params,
    }

    this.denormalizer = denormalizer
  }
}

export const fetchStrawberryToken = (params: ParamsType) => getCallApiFunction(new FetchStrawberryTokenApi(params))
