export const SUB_STATUS = {
  NOT_NECESSARY: -1,
  NEEDED: 0, // 需要但是還沒有
  SUBING: 1, // 準備去sub
  SUBSCRIBED: 2, // 成功
}

export const DEVICE_STATUS = {
  ON: 1,
  OFF: -1,
}

export const HAND_UP_STATUS = {
  ON: 1,
  OFF: -1, // 正常下麥
  CONNECT_OFF: 0, // 斷線
}

export const STREAM_MODE = {
  Unknown: 0,
  FLV: 1,
  RTMP: 2,
  HLS: 3,
}

export const CONNECTION_STATUS = {
  CONNECTING: 'connecting',
  DISCONNECT: 'disconnect',
  NONE: 'none',
}

export const WS_EVENT = {
  SHARE: 'SHARE',
  LIKE: 'LIKE',
  USERIN: 'USERIN',
  ADD2CART: 'ADD2CART',
  STATE: 'STATE',
  CHAT: 'CHAT',
  HAND_UP: 'canHandUp',
}

// 新的 sid，目前開給 shop 用，主要是 likes, share 等...
export const WS_EVENT_SHOP_STATE = {
  sid: 25,
  state: 1,
}

export const WS_EVENT_CHAT = {
  sid: 3,
  MSG: 1,
  TEACHER: 2,
  IT: 3,
  P2P: 4,
  GROUP: 5, // 雲講堂 lobby，shop 聊天室也用這個
  SYSTEM: 6,
  EVALUATE: 7,
  BLOCKDANMU: 8,
  REWARD: 10,
}

export const WS_EVENT_SYSTEM = {
  sid: 1,
  helps: 1,
  refresh: 2,
  assist: 3,
  ready: 4,
  heartbeat: 100,
  chatReport: 6,
  chatReportFeedback: 7,
  timeAlign: 8,
}

// 連麥功能
export const WS_EVENT_LIVE_SHOW = {
  sid: 14,
  handup: 0,
  handup_report: 1,
  connect: 2,
  connect_report: 3,
  connect_list: 4, // 上麥列表
  handup_list: 5, // 舉手列表
  reset: 6,
  reset_report: 7,
  kickoff: 8,
  state: 9,
}

// webRTC
export const WS_EVENT_TMS = {
  sid: 8,
  pub: 1,
  unpub: 2,
  sub: 3,
  unsub: 4,
  addIceCandidate: 5, // 這個對應的文檔的addCandy 請通過 指令好對其
  webReport: 6,
  onpub: 7, // 後端通知需要sub 某個人
  onunpub: 8, // 後端通知需要unsub 某個人
  devices: 9, // 往後端發送設備信息
  onSwitchDevice: 10, // console 通知 切換當前用戶設備
  getReportUrl: 12, // 獲取上報url信息
  change: 13, //換主位(H2H用做螢幕分享)
}

export const LIVE_CODE = {
  0: 'lblSendSuccess',
  1: 'lblNoAccessibility', // '無操作權限',
  2: 'lblConnectLimit', // '連麥已滿4人，請斷開與其他學生連接後再邀請',
  3: 'libHandUpLimit', // '排隊的人太多啦，請十秒後再試喔～',
  4: 'lblNoRepeat', // '不能重複添加',
  5: 'lblNoRoom', // '請求房間不存在不存在',
  6: 'lblParamError', // '請求參數有問題',
  7: '已經上麥不能舉手', // 已經上麥不能舉手
}

export const WS_EVENT_ROOM_STATES = {
  silent: 'silent',
  canFlip: 'canFlip',
  canDraw: 'canDraw',
  chathistory: 'chathistory',
  participant: 'participant',
  active: 'active',
  all: 'all',
  pageId: 'pageId',
  switch: 'switch',
  startTime: 'startTime',
  silentUsers: 'silentUsers',
  userCount: 'userCount',
  vocals: 'vocals',
  members: 'members',
  preferences: 'preferences',
  consultantLastLoginTime: 'consultantLastLoginTime',
  canHandUp: 'canHandUp',
}

export const msgTypes = {
  SYSTEM: 'SYSTEM',
  AUTH: 'AUTH',
  REMOTE: 'REMOTE',
  CHAT: 'CHAT',
  WHITEBOARD: 'WHITEBOARD',
  MATERIAL: 'MATERIAL',
  GAME: 'GAME',
  ROOM: 'ROOM',
  TMS: 'TMS',
  GLOBAL: 'GLOBAL',
  VOTE: 'VOTE',
  GATHER: 'GATHER',
  LIVE_SHOW: 'LIVE_SHOW',
  AD: 'AD',
  GIFT: 'GIFT',
  FESTIVAL: 'FESTIVAL',
  DPPT: 'DPPT',
  COMMON_FUNC: 'COMMON_FUNC',
}

export const WS_EVENT_ROOM = {
  sid: 7,
  userIn: 1,
  userOut: 2,
  reentry: 3,
  state: 10,
  userState: 11,
  control: 12,
  info: 13,
  switch: 90,
  vote: 80,
  members: 14,
  preferences: 15,
  memberInfo: 16,
  teacherInfo: 20,
}

export const SOCKET_STATUS = {
  NONE: 'none',
  CONNECTING: 'connecting',
  CONNECTED: 'connected',
  DISCONNECTED: 'disconnected',
  CONNECT_ERROR: 'connect_error',
}
