import getUrlWithNewSearch from '@/methods/url/getUrlWithNewSearch'
import getSearchParams from '@/methods/url/getSearchParams'
import removeSearchParams from '@/methods/url/removeSearchParams'

type SearchParamsType = {
  fromWhere: string
  media: string
  cct: string
  m: string
  s: string
  mode: string
  locale: string
}

type ParamsType = {
  router: any
  url: string
  searchParams?: SearchParamsType
  isOpen?: boolean
  hasSearchParams?: boolean
  hasLocale?: boolean
}

// 目前只要在 getSearchParams 裡的參數都會透傳
// 不透傳的參數先 remove
const NO_PASS_PARAMS = ['']

const handleLocationHrefDirect = ({ router, url, isOpen = false, hasSearchParams = true, hasLocale = true }: ParamsType) => {
  const searchParams = getSearchParams()
  const localeData = hasLocale ? { locale: router.locale } : {}

  const newUrl =
    getUrlWithNewSearch({
      url,
      isLowerCase: false,
      searchParams: hasSearchParams ? { ...searchParams, ...localeData } : { ...localeData },
    }) || ''

  const finalUrl: any = removeSearchParams({ removeKeyList: NO_PASS_PARAMS, url: newUrl, isLowerCase: false }) // clientSn 不需透傳

  if (isOpen) {
    window.open(finalUrl)
  } else {
    window.location.href = finalUrl
  }
}

export default handleLocationHrefDirect
