// import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'

// Libs
import { fetchSearchHotKeyLive } from '@/api/fetchSearchHotKeyLive'

// Constants
import { QUERY_KEY } from '@/constants/queryKey'

interface Props {
  keyword?: string
}

export type THotKeyLiveData = {
  hotKeyLiveData: {
    liveRoomKeyword: {
      keyword: string
      liveRoomId: string
    }[]
  }
  isLive: boolean
}

const useSearchHotKeyLive = ({ keyword = '' }: Props): THotKeyLiveData => {
  const { data: hotKeyLiveData } = useQuery([QUERY_KEY.FETCH_SEARCH_HOT_KEY_LIVE], fetchSearchHotKeyLive)
  const isLive = keyword ? hotKeyLiveData?.liveRoomKeyword?.find((item: { keyword: string; liveRoomId: string }) => item.keyword === keyword) : false

  return {
    hotKeyLiveData,
    isLive,
  }
}

export default useSearchHotKeyLive
