import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { isEmpty } from 'lodash'

// Libs
import { useAppSelector, useAppDispatch } from '@/redux/hooks'
import { resetDelayTimer } from '@/redux/reducers/common'

// Constants

const useTimerClear = () => {
  const dispatch = useAppDispatch()

  const timerIds = useAppSelector((state) => state.common?.timerIds)

  const router = useRouter()

  useEffect(() => {
    // route 改變時如果還有 timer 就清除
    const handleRouteChange = () => {
      if (!isEmpty(timerIds)) {
        timerIds?.forEach((item) => clearTimeout(item))
        dispatch(resetDelayTimer())
      }
    }

    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router, timerIds])
}

export default useTimerClear
