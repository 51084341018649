import { configureStore } from '@reduxjs/toolkit'
import { createEpicMiddleware } from 'redux-observable'
import commonSlice, { setDelayTimer } from './reducers/common'
import liveSlice from './reducers/live'
import memberSlice from './reducers/member'
import roomSlice from './reducers/room'
import shoppingCartSlice from './reducers/shoppingCart'
import mediaSlice from './reducers/media'
import socketSlice from './reducers/socket'
import orderResultSlice from './reducers/orderResult'
import logSlice from './reducers/log'
import recentLiveStreamingSlice from './reducers/recentLiveStreaming'
import calendarSlice from './reducers/calendar'
import mallSlice from './reducers/mall'
import headerSlice from './reducers/header'

import websocketEpic from './epics/websocketEpic'

const epicMiddleware = createEpicMiddleware()

const delayMiddleware =
  ({ dispatch }: any) =>
  (next: any) =>
  (action: any) => {
    const delaytime = action?.payload?.delay
    if (delaytime) {
      const timerId = setTimeout(() => {
        next(action)
      }, delaytime * 1000)

      dispatch(setDelayTimer({ timerId }))
    } else {
      next(action)
    }
  }

export const store = configureStore({
  reducer: {
    common: commonSlice,
    live: liveSlice,
    member: memberSlice,
    room: roomSlice,
    shoppingCart: shoppingCartSlice,
    media: mediaSlice,
    socket: socketSlice,
    orderResult: orderResultSlice,
    log: logSlice,
    recentLiveStreaming: recentLiveStreamingSlice,
    calendar: calendarSlice,
    mall: mallSlice,
    header: headerSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(epicMiddleware).concat(delayMiddleware),
})

epicMiddleware.run(websocketEpic)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
