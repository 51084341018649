import { ofType } from 'redux-observable'
import { mergeMap } from 'rxjs/operators'
import { EMPTY } from 'rxjs'

import { setUserOffByHostOut } from '@/redux/reducers/socket'

// Libs
import { handleUserConnectStatus } from '@/methods/message/handleUserConnectStatus'
import { handleUnPub, handleUnSub } from '@/methods/message/webRTC'
import { isEmpty } from 'lodash'
import find from 'lodash/find'
import { handleUserHandUpStatus } from '@/methods/message/handleUserHandUpStatus'

export const setUserOffByHostOutAction = ({ action$, state }: any) => {
  return action$.pipe(
    ofType(setUserOffByHostOut.type),
    mergeMap(() => {
      const socketData = state.value.socket
      const { socket, userToken, rtcData, hostData, micOnList, handUpList } = socketData

      handleUnPub({ socket, src: userToken })
      handleUnSub({ socket, src: userToken, target: rtcData.token || hostData?.token })
      if (!isEmpty(find(micOnList, { uid: userToken }))) {
        handleUserConnectStatus({ socket, uid: userToken, isConnect: false, name: '' })
      }
      if (!isEmpty(find(handUpList, { uid: userToken }))) {
        handleUserHandUpStatus({ socket, isHandUp: false, userToken, name: '' })
      }

      return EMPTY
    }),
  )
}
