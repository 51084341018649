import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

// Libs
import useMedia from '@/hooks/useMedia'
import { HeaderActionType } from '@/components/layouts/BasicLayout/components/Header/reducer/headerReducer'
import { handleGtmClickCustomService, handleGtmClickNavigation, handleGtmClickAds } from '@/methods/handleGtmEvent'

// Components
import MenuItem from '../../shared/MenuItem'
import SocialMediaList from '../SocialMediaList'
import Icon from '@/assets/icons'

// Constants
import { EXTERNAL_LINK } from '@/constants/externalLink'
import { LOCALE_LABEL } from '@/constants/defaultLocale'

// types
import { GTM_LOCATION, GtmNavigationParams, GtmClickAdsClickText } from '@/constants/gtmEvent'

// Styles

interface SecondaryMenuProps {
  handleMenuClose: () => void
  headerDispatch?: (action: { type: HeaderActionType }) => void
}

const SecondaryMenu = ({ handleMenuClose, headerDispatch }: SecondaryMenuProps) => {
  const { t } = useTranslation('')
  const { isDesktop } = useMedia()
  const router = useRouter()
  const secondaryMenuData = [
    {
      text: t('Header_Secondary_Menu_Become_Merchant'),
      url: EXTERNAL_LINK.BECOME_MERCHANT,
      handleClick: () => {
        handleGtmClickAds({ clickText: t(GtmClickAdsClickText.RECRUITMENT_MERCHANT, { lng: 'zh-tw' }) })
      },
    },
    {
      text: t('Header_Secondary_Menu_Become_KOL'),
      url: EXTERNAL_LINK.BECOME_KOL,
      handleClick: () => {
        handleGtmClickAds({ clickText: t(GtmClickAdsClickText.RECRUITMENT_KOL, { lng: 'zh-tw' }) })
      },
    },
    {
      text: t('Header_Secondary_Menu_FAQ'),
      url: EXTERNAL_LINK.FAQ,
      handleClick: () => {
        handleGtmClickNavigation({ clickText: t(GtmNavigationParams.FAQ, { lng: 'zh-tw' }) as GtmNavigationParams })
      },
    },
    {
      text: t('Header_Secondary_Menu_Customer_Service_Center'),
      url: EXTERNAL_LINK.CUSTOMER_SERVICE_CENTER,
      handleClick: () => {
        handleGtmClickCustomService({ location: GTM_LOCATION.NAVIGATION })
      },
    },
    {
      text: t('Header_Secondary_Menu_Terms_Of_Service'),
      url: EXTERNAL_LINK.TERMS_OF_SERVICE,
      handleClick: () => {
        handleGtmClickNavigation({ clickText: t(GtmNavigationParams.TERMS_OF_SERVICE, { lng: 'zh-tw' }) as GtmNavigationParams })
      },
    },
    {
      text: t('Header_Secondary_Menu_Return_Policy'),
      url: EXTERNAL_LINK.RETURN_POLICY,
      handleClick: () => {
        handleGtmClickNavigation({ clickText: t(GtmNavigationParams.RETURN_POLICY, { lng: 'zh-tw' }) as GtmNavigationParams })
      },
    },
  ]
  return (
    <>
      {secondaryMenuData.map((item, index) => (
        <MenuItem
          key={index}
          text={item.text}
          url={item.url}
          handleClick={() => {
            item.handleClick()
            handleMenuClose()
          }}
        />
      ))}
      {!isDesktop && (
        <MenuItem
          text={LOCALE_LABEL[router.locale as string]}
          icon={<Icon.Earth />}
          handleClick={() => {
            headerDispatch && headerDispatch({ type: HeaderActionType.HANDLE_LANGUAGE_LIST_OPEN })
          }}
        />
      )}
      <SocialMediaList handleDropdownMenuClose={handleMenuClose} />
    </>
  )
}
export default SecondaryMenu
