import Service, { getCallApiFunction } from '../service'
// import denormalizer from './denormalizer'
// import normalizer from './normalizer'

type RefreshTokenParamsType = {
  refreshToken: string
}

class FetchRefreshTokenApi extends Service {
  constructor(params: RefreshTokenParamsType) {
    super()
    this.name = 'FETCH_REFRESH_TOKEN'

    this.config = {
      url: `/v1/user-auth/refresh-token`,
      method: 'GET',
      params,
    }
  }
}

export const fetchRefreshToken = (params: RefreshTokenParamsType) => getCallApiFunction(new FetchRefreshTokenApi(params))
