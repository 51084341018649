import classnames from 'classnames/bind'

// Libs

// Components
import Link from '@/components/common/Link'

// Constants

// Styles
import styles from './style.module.scss'

const cx = classnames.bind(styles)
interface MenuItemProps {
  text: string
  url?: string
  icon?: React.ReactNode
  handleClick: () => void
}

const MenuItem = ({ text, url, handleClick, icon }: MenuItemProps) => {
  return (
    <li onClick={() => handleClick()}>
      {url ? (
        <Link className={cx('menu-item')} url={url} isOpen>
          <>
            {icon && <div className={cx('menu-item--icon')}>{icon}</div>}
            <h4>{text}</h4>
          </>
        </Link>
      ) : (
        <div className={cx('menu-item')}>
          {icon && <div className={cx('menu-item--icon')}>{icon}</div>}
          <p>{text}</p>
        </div>
      )}
    </li>
  )
}
export default MenuItem
