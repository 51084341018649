import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { debounce } from 'lodash'

// Libs
import { setCurrentLayout } from '@/redux/reducers/common'

// Constants
import { LAYOUT, MAX_MOBILE_WIDTH, MAX_TABLET_WIDTH, MAX_DESKTOP_M_WIDTH, MAX_DESKTOP_MIDDLE_WIDTH } from '@/constants/layout'

export const useLayout = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    const handleWindowWidth = () => {
      const mediaQuery = window.matchMedia('(orientation: portrait)')

      const isPortrait = mediaQuery?.matches

      if (window.innerWidth < MAX_MOBILE_WIDTH) {
        dispatch(setCurrentLayout({ currentLayout: LAYOUT.MOBILE, isPortrait }))
      } else if (window.innerWidth >= MAX_MOBILE_WIDTH && window.innerWidth < MAX_TABLET_WIDTH) {
        dispatch(setCurrentLayout({ currentLayout: LAYOUT.TABLET, isPortrait }))
      } else {
        dispatch(
          setCurrentLayout({
            currentLayout: LAYOUT.DESKTOP,
            isDesktopM: window.innerWidth < MAX_DESKTOP_M_WIDTH,
            isDesktopMiddle: window.innerWidth < MAX_DESKTOP_MIDDLE_WIDTH,
            isPortrait,
          }),
        )
      }
    }

    handleWindowWidth()

    window.addEventListener('resize', debounce(handleWindowWidth, 300))

    return () => {
      window.removeEventListener('resize', handleWindowWidth)
    }
  }, [dispatch])
}
