export const LAYOUT = {
  MOBILE: 'mobile',
  TABLET: 'tablet',
  DESKTOP: 'desktop',
}

export const MAX_MOBILE_WIDTH = 710
export const MAX_TABLET_WIDTH = 1024
export const MAX_DESKTOP_MIDDLE_WIDTH = 1200 // 在直播間需要 1200 斷點
export const MAX_DESKTOP_M_WIDTH = 1440
