import { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import ReactDOM from 'react-dom'
import type { ReactNode } from 'react'
import classnames from 'classnames/bind'
import { useTranslation } from 'next-i18next'

// Libs
import { handleStringArray } from '@/methods/handleStringArray'
import { useIsCSR } from '@/hooks/useIsCSR'

// Components
import Button from '@/components/common/Button'

// Styles
import styles from './style.module.scss'
import Icon from '@/assets/icons'

// Variables
const cx = classnames.bind(styles)

// PropTypes
type PopType = {
  children?: ReactNode
  title?: string | string[]
  confirmLabel?: string
  cancelLabel?: string
  onConfirm?: any
  onCancel?: any
  hasConfirm?: boolean
  hasCancel?: boolean
  isMaskClosable?: boolean
  canClose?: boolean
  isLoading?: boolean
  isDisabledConfirm?: boolean
  hasFooter?: boolean
  popupClassname?: string
  wrapperClassname?: string
  isDrawer?: boolean
  isOpen?: boolean
  isWrapperWithCancel?: boolean
  isHeightAuto?: boolean
  customContainer?: any
}

export type TPopupRef = {
  isPopupOpen: boolean
  setIsPopupOpen: (isOpen: boolean) => void
}

const defaultProps = {
  confirmLabel: 'Common_Ok',
  cancelLabel: 'Common_Cancel',
  onConfirm: () => {},
  onCancel: () => {},
  hasConfirm: true,
  hasCancel: true,
  isMaskClosable: true,
  canClose: true,
  isDrawer: false, // 此專案 Popup 在手機版可能會有 dialog 或 drawer 的顯示方式，已跟設計確認會有這需求
  isOpen: false,
  isWrapperWithCancel: true,
}

const Popup = forwardRef((props: PopType, ref) => {
  const {
    children,
    title,
    onConfirm,
    onCancel,
    confirmLabel,
    cancelLabel,
    isMaskClosable,
    canClose,
    popupClassname,
    wrapperClassname,
    hasConfirm,
    hasCancel,
    isDrawer,
    isOpen,
    isDisabledConfirm,
    isWrapperWithCancel,
    isHeightAuto,
    customContainer,
  } = props

  const { t } = useTranslation()

  const [isPopupOpen, setIsPopupOpen] = useState(false)

  const { isCSR } = useIsCSR()

  const popupContainer: any = typeof window === 'undefined' ? null : document.getElementById('app')

  useEffect(() => {
    if (isPopupOpen) document.body.style.overflow = 'hidden'

    return () => {
      if (isPopupOpen) document.body.style.overflow = 'initial'
    }
  }, [isPopupOpen])

  useEffect(() => {
    if (isOpen) {
      setIsPopupOpen(true)
    } else {
      setIsPopupOpen(false)
    }
  }, [isOpen])

  useImperativeHandle(ref, () => ({
    isPopupOpen,
    setIsPopupOpen,
  }))

  const handleWrapperClick = () => {
    if (isWrapperWithCancel) onCancel()

    if (canClose && isMaskClosable) {
      setIsPopupOpen(false)
    }
  }

  return (
    <div>
      {isCSR &&
        ReactDOM.createPortal(
          <div
            onClick={(e: any) => e.stopPropagation()}
            className={cx('popup', popupClassname, { isDrawer, isOpen: isPopupOpen })}
            data-is-open={isPopupOpen}
          >
            <div className={cx('popup-background', { isDrawer })} onClick={handleWrapperClick} />
            <div className={cx('popup-wrapper', wrapperClassname, { isDrawer, isHeightAuto })} data-is-open={isPopupOpen}>
              {title && (
                <div className={cx('popup-wrapper__header')}>
                  <div className={cx('popup-wrapper__header-title')}>{handleStringArray(title)}</div>
                </div>
              )}
              {isPopupOpen && children && <div className={cx('popup-wrapper__body')}>{children}</div>}
              {(hasConfirm || hasCancel) && (
                <div className={cx('popup-wrapper__action')}>
                  {hasCancel && (
                    <Button className={cx('popup-wrapper__action-button')} size='md' isOutline onClick={onCancel}>
                      {t(cancelLabel as string)}
                    </Button>
                  )}
                  {hasConfirm && (
                    <Button className={cx('popup-wrapper__action-button')} size='md' onClick={onConfirm} disabled={isDisabledConfirm}>
                      {t(confirmLabel as string)}
                    </Button>
                  )}
                </div>
              )}

              {canClose && !isDrawer && (
                <div className={cx('popup-wrapper__close')} onClick={handleWrapperClick}>
                  <Icon.Close />
                </div>
              )}
            </div>
          </div>,
          customContainer || popupContainer,
        )}
    </div>
  )
})

Popup.defaultProps = defaultProps

export default Popup
