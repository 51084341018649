import classnames from 'classnames/bind'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

// Libs
import { handleGtmSocialChannel, handleGtmClickCustomService } from '@/methods/handleGtmEvent'

// Components
import Icon from '@/assets/icons'
import Link from '@/components/common/Link'

// Constants
import { ROUTE_PATH } from '@/constants/routePath'
import { GTM_LOCATION, GtmSocialChannelChannel } from '@/constants/gtmEvent'

// Styles
import styles from './style.module.scss'

// Variables
const cx = classnames.bind(styles)

function Footer() {
  const { t } = useTranslation()
  const router = useRouter()
  const currentYear = new Date().getFullYear()

  const handleSendTrackEvent = () => {
    const isProductDetailPage = router.pathname === ROUTE_PATH.PRODUCT_DETAIL

    if (isProductDetailPage) {
      handleGtmClickCustomService({ location: GTM_LOCATION.PRODUCT_DETAIL })
    }
  }

  return (
    <div className={cx('footer')}>
      <div className={cx('container')}>
        <div className={cx('footer__link')}>
          <Link
            handleClick={() => {
              handleGtmSocialChannel({ socialChannel: GtmSocialChannelChannel.FACEBOOK, location: GTM_LOCATION.FOOTER })
            }}
            url='https://www.facebook.com/tutorabcshop'
            isOpen
          >
            <div className={cx('footer__icon')}>
              <Icon.Facebook data-alt='Facebook' />
            </div>
          </Link>
          <Link
            handleClick={() => {
              handleGtmSocialChannel({ socialChannel: GtmSocialChannelChannel.LINE, location: GTM_LOCATION.FOOTER })
              handleSendTrackEvent()
            }}
            url='https://lin.ee/eNY9yaC'
            isOpen
          >
            <div className={cx('footer__icon')}>
              <Icon.MenuLine data-alt='LINE' />
            </div>
          </Link>
          <Link
            handleClick={() => {
              handleGtmSocialChannel({ socialChannel: GtmSocialChannelChannel.INSTAGRAM, location: GTM_LOCATION.FOOTER })
            }}
            url='https://instagram.com/tutorabcshop'
            isOpen
          >
            <div className={cx('footer__icon')}>
              <Icon.Instagram data-alt='Instagram' />
            </div>
          </Link>
        </div>

        <div className={cx('footer__bottom')}>
          <p className={cx('footer__bottom-copy')}>{`Copyright© ${currentYear} TutorABC Shop`}</p>
          <div className={cx('footer__bottom-description')}>
            <div className={cx('footer__bottom-description-section')}>
              <p>{t('Common_Company_Name')}</p>
              <p>{t('Common_Tax_ID', { taxId: '94059616' })}</p>
            </div>
            <div className={cx('footer__bottom-description-section')}>
              <p>{`(02)33659988`}</p>
              <Link isMail isOpen url='shopservice@tutorabcshop.com'>
                <p className={cx('footer__bottom-description-email')}>{`shopservice@tutorabcshop.com`}</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
