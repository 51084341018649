import { useEffect } from 'react'
import { isEmpty } from 'lodash'
import { useRouter } from 'next/router'

// Libs
import { useAppSelector, useAppDispatch } from '@/redux/hooks'
import { setFixedPlayerData } from '@/redux/reducers/common'
import { setLiveType } from '@/redux/reducers/room'

// Constants
import { STORAGE_DATA } from '@/constants/storageData'
import { ROOM_LIVE_TYPE } from '@/constants/liveRoom'
import { ROUTE_PATH } from '@/constants/routePath'

const useFixedPlayerByStorage = () => {
  const dispatch = useAppDispatch()

  const memberData = useAppSelector((state) => state.member?.memberData)

  const router = useRouter()
  const { pathname } = router

  const fixedPlayerCacheData: any = typeof window !== 'undefined' && window.sessionStorage.getItem(STORAGE_DATA.FIXED_PLAYER_CACHE_DATA)

  const { memberInfoId } = memberData

  const isLogin = !!memberInfoId

  const isLiveRoomPath = pathname === ROUTE_PATH.LIVE_ROOM || pathname === ROUTE_PATH.STRAWBERRY_LIVE_ROOM

  useEffect(() => {
    // 如果 localstorage FAST_CHECKOUT_CACHE_DATA 有東西，代表要執行立即購買登入回來的流程
    if (isLogin && !isEmpty(window) && !isLiveRoomPath) {
      if (!isEmpty(fixedPlayerCacheData)) {
        const fixedPlayerData = JSON.parse(fixedPlayerCacheData)

        dispatch(setFixedPlayerData({ fixedPlayerData }))
        dispatch(setLiveType({ liveType: ROOM_LIVE_TYPE.CURRENT }))

        window.sessionStorage.removeItem(STORAGE_DATA.FIXED_PLAYER_CACHE_DATA)
      }
    }
  }, [isLogin, isLiveRoomPath])
}

export default useFixedPlayerByStorage
