import { handleMessageEmit } from '@/methods/message/handleMessageEmit'

import { WS_EVENT_TMS } from '@/constants/socketEvent'

export const handleUnPub = ({ socket, src }: { socket: any; src: string }) => {
  const unPubPacket = {
    sid: WS_EVENT_TMS.sid,
    ssid: WS_EVENT_TMS.unpub,
    body: {
      src,
      streamType: '',
    },
  }

  handleMessageEmit({ socket, content: unPubPacket })
}

export const handleUnSub = ({ socket, src, target }: { socket: any; src: string; target: string }) => {
  const unSubPacket = {
    sid: WS_EVENT_TMS.sid,
    ssid: WS_EVENT_TMS.unsub,

    body: {
      streamType: '',
      src,
      target,
    },
  }

  handleMessageEmit({ socket, content: unSubPacket })
}
