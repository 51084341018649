import { setFastCheckoutItem } from '@/redux/reducers/shoppingCart'
import handleRoutePushWithQuery from '@/methods/url/handleRoutePushWithQuery'

// Constants
import { ROUTE_PATH } from '@/constants/routePath'

const fastCheckout = ({ router, dispatch, fastCheckoutItem }: any) => {
  const fastCheckoutItemPayload = fastCheckoutItem

  dispatch(setFastCheckoutItem(fastCheckoutItemPayload))
  handleRoutePushWithQuery({ router, pathname: ROUTE_PATH.SHOPPINGCART })
}
export default fastCheckout
