import { BRAND_TYPE } from '@/constants/constants'

export function getParameterByName(name) {
  const regex = new RegExp('[?&]' + name.replace(/[[]]/g, '\\$&') + '(=([^&#]*)|&|#|$)')
  const results = regex.exec(window.location.href)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

/**
 * 直接下载url 不跳转
 */
export const downloadUrl = ({ url = '' }) => {
  let a = document.createElement('a')
  a.href = url
  a.target = '_blank'
  // a.download = filename;
  a.click()
}

export const openInNewTab = (url) => {
  let a = document.createElement('a')
  a.href = url
  a.target = '_blank'
  a.click()
}

const getOrigin = () => {
  if (typeof window === 'undefined') return ''
  let origin = window.location.origin
  if (origin.indexOf('localhost') > -1) {
    origin = ''
  }
  return origin
}

let apiHost = 'https://api.tutormeet.com'
let programHost = 'https://www.pahx.com/kid/programing/api/index/recording'
let materialHost = 'https://tubing.tutormeet.com'
let dictionaryUrl = `${getOrigin()}/v2/carnation/external/dictSearch`
let feedbackUrl = `${getOrigin()}/v2/carnation/external/room-commence`
let playbackRecordHost = '"https://console.tutormeet.com/v2/console/api"'
let marqueeUrl = `${getOrigin()}/v2/carnation/external/room-notice`
let forward = `${getOrigin()}/v2/carnation/external/forward`

let starJourneyUrl = 'https://bsgw.pahx.com/api/starjourney/callback/queryClientInfo'
let profileUrl = `${getOrigin()}/v2/carnation/external/customer-profile`
let voteRankUrl = 'https://www5.tutormeet.com/v2/carnation/external/votes'
let personTagsUrl = `http://twgtapi-cpp.tutorabc.com/customerTag/listTags`
let transformPinyinTranslateUrl = `https://service.tutorabcchinese.com/api/Common/PinyinTranslate`
let transformPinyinUrl = `https://service.tutorabcchinese.com/api/Common/Pinyin`
let materialTagsUrl = 'http://tw-api.mat.tutorabc.com/materialApi/recordMaterialQuestions'
let getUserPeriod = `http://mpt.mem.tutorabc.com/portrait/privateapi/customerTag/inPeriodPayClientList`
let rewardUrl = `${getOrigin()}/v2/carnation/external/octopus`
let queryIntroduction = 'https://mobileclt.tutorabc.com/consultant/graduationCertification/profile/queryIntroduction' //获取顾问的自我介绍图片
//{code: 0, data: { logo: {source: "https://www.baidu.com" , displayArea: "left" }}}
let configUrl = '/v2/carnation/external/octopus/room-render'
let getEmojis = `${getOrigin()}/v2/carnation/external/octopus/emoji` //获取贴纸配置
let getAtmosphereConfig = `${getOrigin()}/v2/carnation/external/octopus/gift-config` // 获取活动动效 json 文件地址
let getAtmosphereConfigByCondition = `${getOrigin()}/v2/carnation/external/octopus/room-render/animation-setting` // 获取活动动效 json 文件地址
let dyncMaterialsUrl = `${getOrigin()}/v2/tubing/materials`
let getMaterialType = `${getOrigin()}/v2/tubing/materials/type`
let getRoomUi = `${getOrigin()}/v2/carnation/external/octopus/room-render/ui-setting` // 皮肤设置
// 图库
const imgGallery = `${getOrigin()}/v2/tango/images/search`
const imgGalleryPixabay = 'https://pixabay.com/api/?key=7475856-892dd74ad9bf5bd060a772e5f'
const imgFeedback = `${getOrigin()}/v2/carnation/external/octopus/web/imgstore`
const imgTagFeedback = `${getOrigin()}/v2/carnation/external/octopus/web/imgtag`
let adultFeedback = 'http://mobileclt.tutorabc.com/consultant/api/progressquick/save'
let trackerUrl = 'https://apm.tutormeet.com/tip.gif'
let mailAction = 'https://www5.tutormeet.com/tmops/out/v1/ops/emailTogether'

if (process.env.DEPLOYMENT_ENV === 'qa') {
  apiHost = 'https://api-qa.tutormeet.com'
  materialHost = 'https://tubing-qa.tutormeet.com'
  dictionaryUrl = 'https://www5-qa.tutormeet.com/v2/carnation/external/dictSearch'
  feedbackUrl = 'https://www5-qa.tutormeet.com/v2/carnation/external/room-commence'
  playbackRecordHost = 'https://www5-qa.tutormeet.com/v2/console/api'
  marqueeUrl = 'https://www5-qa.tutormeet.com/v2/carnation/external/room-notice'
  forward = `https://www5-qa.tutormeet.com/v2/carnation/external/forward`
  starJourneyUrl = 'http://172.16.235.200/api/starjourney/callback/queryClientInfo'
  profileUrl = 'https://www5-qa.tutormeet.com/v2/carnation/external/customer-profile'
  voteRankUrl = 'https://www5-qa.tutormeet.com/v2/carnation/external/votes'
  personTagsUrl = 'http://twgtapi-cpp.tutorabc.com/customerTag/listTags'
  materialTagsUrl = 'http://tw-api.mat.tutorabc.com/materialApi/recordMaterialQuestions'
  queryIntroduction = 'http://mobileclt.tutorabc.com/consultant/graduationCertification/profile/queryIntroduction'
  configUrl = '/v2/carnation/external/octopus/room-render'
  getEmojis = 'https://www5-qa.tutormeet.com/v2/octopus/emoji'
  getAtmosphereConfig = `https://www5-qa.tutormeet.com/v2/octopus/gift-config` // 获取活动动效 json 文件地址
  getAtmosphereConfigByCondition = `https://www5-qa.tutormeet.com/v2/octopus/room-render/animation-setting` // 获取活动动效 json 文件地址
  dyncMaterialsUrl = 'https://tubing-qa.tutormeet.com/v2/tubing/materials'
  getMaterialType = `https://tubing-qa.tutormeet.com/v2/tubing/materials/type`
  getRoomUi = `https://www5-qa.tutormeet.com/v2/octopus/room-render/ui-setting`
  adultFeedback = 'http://mobileclt.tutorabc.com/consultant/api/progressquick/save'
  trackerUrl = 'https://apm-qa.tutormeet.com/tip.gif'
  mailAction = 'https://www5-qa.tutormeet.com/tmops/out/v1/ops/emailTogether'
} else if (process.env.DEPLOYMENT_ENV === 'stage') {
  apiHost = 'https://api-stage.tutormeet.com'
  programHost = 'https://code.passport.weitutorstage.com/index/recording'
  materialHost = 'https://tubing-stage.tutormeet.com'
  dictionaryUrl = `${getOrigin()}/v2/carnation/external/dictSearch`
  feedbackUrl = '/v2/carnation/external/room-commence'
  playbackRecordHost = 'https://www5-stage.tutormeet.com/v2/console/api'
  marqueeUrl = 'https://www5-stage.tutormeet.com/v2/carnation/external/room-notice'
  forward = '/v2/carnation/external/forward'
  rewardUrl = 'https://www5-stage.tutormeet.com/v2/carnation/external/octopus'
  starJourneyUrl = 'http://172.16.55.86/api/starjourney/callback/queryClientInfo'
  profileUrl = `${getOrigin()}/v2/carnation/external/customer-profile`
  voteRankUrl = 'https://www5-stage.tutormeet.com/v2/carnation/external/votes'
  personTagsUrl = 'http://twgtapi-cpp.tutorabc.com/customerTag/listTags'
  materialTagsUrl = 'http://tw-api.mat.tutorabc.com/materialApi/recordMaterialQuestions'
  queryIntroduction = 'https://mobileclt.tutorabc.com/consultant/graduationCertification/profile/queryIntroduction'
  configUrl = '/v2/carnation/external/octopus/room-render'
  getEmojis = 'https://www5-stage.tutormeet.com/v2/octopus/emoji'
  getAtmosphereConfig = `https://www5-stage.tutormeet.com/v2/octopus/gift-config` // 获取活动动效 json 文件地址
  getAtmosphereConfigByCondition = `https://www5-stage.tutormeet.com/v2/octopus/room-render/animation-setting` // 获取活动动效 json 文件地址
  dyncMaterialsUrl = 'https://tubing-stage.tutormeet.com/v2/tubing/materials'
  getMaterialType = `https://tubing-stage.tutormeet.com/v2/tubing/materials/type`
  getRoomUi = `https://www5-stage.tutormeet.com/v2/octopus/room-render/ui-setting`
  adultFeedback = 'http://mobileclt.tutorabc.com/consultant/api/progressquick/save'
  trackerUrl = 'https://apm-stage.tutormeet.com/tip.gif'
  mailAction = 'https://www5-stage.tutormeet.com/tmops/out/v1/ops/emailTogether'
}

const getProgramHost = (brandId) => {
  const isTBrand = brandId === BRAND_TYPE.TJR || brandId === BRAND_TYPE.T || brandId === BRAND_TYPE.TJR_1
  let host = isTBrand ? 'https://programming.tutorjr.com' : 'https://www.pahx.com/kid/programing/api'
  if (process.env.DEPLOYMENT_ENV === 'stage') {
    host = isTBrand ? 'https://stage-programing.tutorjr.com' : 'https://code.passport.weitutorstage.com'
  }
  return host
}

export const host = {
  apiHost,
  programHost,
  getProgramHost,
  materialHost,
  dictionaryUrl,
  feedbackUrl,
  playbackRecordHost,
  marqueeUrl,
  forward,
  starJourneyUrl,
  profileUrl,
  voteRankUrl,
  personTagsUrl,
  transformPinyinTranslateUrl,
  transformPinyinUrl,
  materialTagsUrl,
  getUserPeriod,
  rewardUrl,
  queryIntroduction,
  configUrl,
  getEmojis,
  getAtmosphereConfig,
  dyncMaterialsUrl,
  getMaterialType,
  getRoomUi,
  imgGallery,
  imgGalleryPixabay,
  imgFeedback,
  imgTagFeedback,
  getAtmosphereConfigByCondition,
  adultFeedback,
  trackerUrl,
  mailAction,
}
export default host
