import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { useMutation } from 'react-query'

// Libs
import { handleLoginOrRegister } from '@/methods/handleLoginOrRegister'
import { setUserRecord } from '@/api/setUserRecord'
import { useAppSelector, useAppDispatch } from '@/redux/hooks'
import { setShowLoading } from '@/redux/reducers/common'

// Constants
import { STORAGE_DATA } from '@/constants/storageData'
import { ROUTE_PATH } from '@/constants/routePath'

// Types

const AUTHORIZATION_PATH = [ROUTE_PATH.ORDERS, ROUTE_PATH.ORDER_RESULT, ROUTE_PATH.ME, ROUTE_PATH.MY_LIVE_FUTURE, ROUTE_PATH.MY_LIVE_PAST]

export const useAuthorization = () => {
  const router = useRouter()
  const { pathname } = router

  const fixedPlayerData = useAppSelector((state) => state.common.fixedPlayerData)

  const dispatch = useAppDispatch()

  const setUserRecordMutation = useMutation(setUserRecord)

  useEffect(() => {
    if (typeof window === 'undefined') return

    const tokenFromLocalStorage = JSON.parse(<string>window.localStorage.getItem(STORAGE_DATA.TOKEN))

    const isAuthorizationPath = AUTHORIZATION_PATH.includes(pathname)

    if (!tokenFromLocalStorage && isAuthorizationPath) {
      dispatch(setShowLoading({ showLoading: true }))

      // 這裡的情境不用送 gtm even
      handleLoginOrRegister({ isLogin: true, router, setUserRecordMutation, fixedPlayerData, isGoRedirect: true })
    }
  }, [pathname])
}
