import { isEmpty, isFunction } from 'lodash'
import { toast } from 'react-toastify'
import { execute } from '@tutorabc/webview-jsbridge'

// Libs
import { updateCartDataThunk } from '@/redux/reducers/shoppingCart'
import { THandleGtmUpdateCart, handleGtmUpdateCart } from './handleGtmEvent'
import { isClientSideShopApp } from '@/utils/navigator'

// Constants
import { BRIDGE_HANDLER } from '@/constants/bridgeHandler'

type SkuListItem = {
  quantity: number
  skuId: number
  roomId?: string
  referralFrom?: string
  referralId?: string
}

export type ParamsType = {
  t: any
  dispatch: any
  action: number
  quantity?: number
  roomId?: string
  skuId?: number
  skuList?: SkuListItem[]
  message?: string
  disableSuccessMessage?: boolean
  successCallback?: any
  errorCallback?: any
  gtmUpdateCartData?: THandleGtmUpdateCart
}

export const handleCartDataUpdate = async ({
  t,
  dispatch,
  action,
  quantity,
  roomId = '',
  skuId,
  skuList,
  message = '',
  disableSuccessMessage = false,
  successCallback,
  errorCallback,
  gtmUpdateCartData,
}: ParamsType) => {
  return await dispatch(
    updateCartDataThunk({
      action,
      quantity,
      roomId,
      skuId,
      skuList,
    }),
  )
    .unwrap()
    .then(() => {
      if (!disableSuccessMessage) {
        toast.success(message || t('Common_AddToCart_Success'))
      }

      if (isClientSideShopApp) {
        execute({
          handlerName: BRIDGE_HANDLER.REFRESH_CART,
          data: {
            action,
          },
        })
      }

      if (isFunction(successCallback)) {
        successCallback()
      }
      !isEmpty(gtmUpdateCartData) && handleGtmUpdateCart(gtmUpdateCartData)
    })

    .catch((error: any) => {
      console.error('handleCartDataUpdate error ', error)

      if (isFunction(errorCallback)) {
        errorCallback()
      }

      if (error.message === '庫存不足') {
        toast.error(t('Common_AddToCart_Limit'))
      } else {
        toast.error(error.message)
      }
    })
}
