import { useState, useEffect } from 'react'

function useFullScreen() {
  const [isFullScreen, setIsFullScreen] = useState(false)

  useEffect(() => {
    setIsFullScreen(!!document.fullscreenElement)
  }, [])

  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsFullScreen(!!document.fullscreenElement)
    }

    document.addEventListener('fullscreenchange', handleFullScreenChange)

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange)
    }
  }, [])

  return { isFullScreen }
}
export default useFullScreen
