import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { find, cloneDeep, findIndex } from 'lodash'

// Libs
import { fetchRoomUserListByUid, DataType } from '@/api/fetchRoomUserListByUid'

// Constant
import { WS_EVENT_LIVE_SHOW, CONNECTION_STATUS, HAND_UP_STATUS, SOCKET_STATUS } from '@/constants/socketEvent'
import { NETWORK_STATUS_QUALITY } from '@/constants/liveRoom'

// Types
import { SocketDataType } from '@/types/room'
import { getUACode } from '@/methods/getUAData'

// Thunk
export const fetchRoomUserListByUidThunk = createAsyncThunk('socket/fetchRoomUserListByUid', async (data: DataType) => {
  const response = await fetchRoomUserListByUid(data)

  return response
})

const COUNTDOWN = 10

const initialState: SocketDataType = {
  isReady: false,
  socket: null,
  roomId: '',
  hostData: {},
  rtcData: {},
  userToken: '',
  targetToken: '',
  socketUrl: '',
  socketHost: '',
  socketToken: '',
  socketRegion: '',
  notificationList: [],
  currentViews: 0, // 正在觀看
  likeList: [],
  messageList: [],
  micList: [],
  handUpList: [],
  micOnList: [],
  participantList: [],
  stateData: {
    likes: 0,
    views: 0, // 總觀看
    topProductList: [],
    publishProductList: [],
  },
  userCount: 0,
  canHandUp: false,
  isCountdowning: false,
  countdownSecond: COUNTDOWN,
  isUnsub: false,
  connectStatus: CONNECTION_STATUS.NONE,
  localStream: {},
  audience: {
    token: '',
    camera: 1,
    mic: 1,
  },
  isKickoff: false,
  isNetworkError: false,
  isRoomIdChanged: false,
  socketStatus: SOCKET_STATUS.NONE,
  reportInfo: {
    sessionId: '',
    statusUrl: '',
    webrtcStatsDuration: 20000,
    webrtcStatsUrl: '',
  },
  rtcStats: {},
  networkStatus: {
    rtt: 0,
    lostRate: 0,
    sendBitRate: 0,
    recvBitRate: 0,
    quality: NETWORK_STATUS_QUALITY.GOOD,
  },
  isShowUserOutPopup: false,
  silentUserList: [],
}

const webSocketSlice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    setSocketUrl: (state, { payload }) => {
      state.socketHost = 'socketHost' in payload ? payload.socketHost : state.socketHost
      state.socketToken = 'socketToken' in payload ? payload.socketToken : state.socketToken
      state.socketRegion = 'socketRegion' in payload ? payload.socketRegion : state.socketRegion
      state.socketUrl = `https://${state.socketHost}?t=${state.socketToken}&r=${state.socketRegion}&o=${getUACode()}&EIO=3&transport=websocket`
      state.roomId = 'roomId' in payload ? payload.roomId : state.roomId
    },
    resetSocket: () => {
      return initialState
    },
    setSocketStatus: (state, { payload }) => {
      state.socketStatus = payload.socketStatus
    },
    setReady: (state, { payload }) => {
      state.isReady = 'isReady' in payload ? payload.isReady : state.isReady
      state.socket = 'socket' in payload ? payload.socket : state.socket
      state.userToken = 'userToken' in payload ? payload.userToken : state.userToken
    },
    setState: (state, { payload }) => {
      state.stateData = payload.stateData
    },
    setLike: (state, { payload }) => {
      state.stateData.likes = payload.stateData.likes
    },
    setUserCount: (state, { payload }) => {
      state.userCount = payload.userCount
    },
    setLikeList: (state, { payload }) => {
      state.likeList = [...state.likeList, payload.likes]
    },
    resetLikeList: (state) => {
      state.likeList = initialState.likeList
    },
    setChathistory: (state, { payload }) => {
      state.messageList = [...state.messageList, ...payload.chathistory]
    },
    setMessageList: (state, { payload }) => {
      state.messageList = [...state.messageList, payload.messageData].sort((a, b) => {
        if (a.sort === 1) {
          return -1 // 將 sort 為 1 的元素排在前面
        } else if (b.sort === 1) {
          return 1 // 將 sort 為 1 的元素排在前面
        } else {
          return 0 // 其他情況保持原本順序
        }
      })
    },
    setNotificationList: (state, { payload }) => {
      const messageData = {
        index: payload.notificationData.index,
        name: payload.notificationData.name,
        context: payload.notificationData.context,
        type: payload.notificationData.type,
      }

      state.notificationList = [...state.notificationList, payload.notificationData]
      state.messageList = [...state.messageList, messageData] // 目前在桌機版的通知要顯示在 MessageList，所以 setNotificationList 時也 setMessageList
    },
    resetSocketData: (state) => {
      // 因為有要留個 socket 但有些資料要清空的情境
      // 例如觀眾離開直播間，socket 要留著，但 notificationList 跟 messageList 要清空
      // 目前要再觀察還有什麼要清空
      state.notificationList = initialState.notificationList
      state.messageList = initialState.messageList
    },
    resetChatData: (state) => {
      const placard = find(state.messageList, { type: 'placard' })
      state.messageList = initialState.messageList
      placard && (state.messageList = [...state.messageList, placard])
    },
    updateUserOnPubInfo: (state, { payload }) => {
      state.rtcData = { ...state.rtcData, ...payload.rtcData }
    },
    resetUserOnPubInfo: (state) => {
      state.rtcData = initialState.rtcData
    },
    updateHandUpStatus: (state, { payload }) => {
      state.canHandUp = payload.canHandUp
    },
    updateHandUpList: (state, { payload }) => {
      state.handUpList = payload.handUpList
    },
    updateMicOnList: (state, { payload }) => {
      state.micOnList = payload.micOnList
    },
    updateTargetToken: (state, { payload }) => {
      state.targetToken = payload.targetToken
    },
    addHandUpItem: (state, { payload }) => {
      state.handUpList = [...state.handUpList, payload.handUpItem]
    },
    removeHandUpItem: (state, { payload }) => {
      const selectedIndex = findIndex(state.handUpList, { uid: payload?.uid })

      const cloneHandUpList = cloneDeep(state.handUpList)

      const newHandUpList = [...cloneHandUpList.slice(0, selectedIndex), ...cloneHandUpList.slice(selectedIndex + 1, cloneHandUpList.length)]

      state.handUpList = newHandUpList
    },
    setCountdownSecond: (state, { payload }) => {
      state.countdownSecond = payload.countdownSecond
    },
    setIsCountdowning: (state, { payload }) => {
      state.isCountdowning = payload.isCountdowning
    },
    resetCountdownSecond: (state) => {
      state.countdownSecond = COUNTDOWN
      state.isCountdowning = false
    },
    addMicOnItem: (state, { payload }) => {
      state.micOnList = [...state.micOnList, payload.micOnItem]
    },
    removeMicOnItem: (state, { payload }) => {
      const selectedIndex = findIndex(state.micOnList, { uid: payload?.uid })

      const cloneMicOnList = cloneDeep(state.micOnList)

      const newMicOnList = [...cloneMicOnList.slice(0, selectedIndex), ...cloneMicOnList.slice(selectedIndex + 1, cloneMicOnList.length)]

      state.micOnList = newMicOnList

      // 移除 micOnList 時，同時移除 handUpList
      const selectedHandUpIndex = findIndex(state.handUpList, { uid: payload?.uid })

      const cloneHandUpList = cloneDeep(state.handUpList)

      const newHandUpList = [
        ...cloneHandUpList.slice(0, selectedHandUpIndex),
        ...cloneHandUpList.slice(selectedHandUpIndex + 1, cloneHandUpList.length),
      ]

      state.handUpList = newHandUpList
    },
    setHostData: (state, { payload }) => {
      state.hostData = { ...state.hostData, ...payload.hostData }
    },
    setParticipantList: (state, { payload }) => {
      state.participantList = payload.participantList
    },

    setRoomHandUpClose: (state, { payload }) => {
      state.canHandUp = payload.canHandUp
    },
    setRoomHandUpStatus: (state, { payload }) => {
      state.canHandUp = payload.canHandUp
    },
    setHostUnSub: (state, { payload }) => {
      state.isUnsub = payload.isUnsub
    },
    setUserOffByHostOut: (state) => {
      state.rtcData = initialState.rtcData
      state.connectStatus = CONNECTION_STATUS.DISCONNECT
    },
    setHostConnectUser: (state, { payload }) => {
      state.targetToken = payload.targetToken

      const micOnItem = { uid: payload.targetToken, status: HAND_UP_STATUS.ON, name: payload.name }

      state.micOnList = [...state.micOnList, micOnItem]
    },
    // FIXME: 不列 payload 的話， dispatch(setHostDisconnectUser({ uid })) 會有錯誤，這裡的 uid 是給 epic 用
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setHostDisconnectUser: (state, { payload }) => {
      state.isUnsub = true
      state.rtcData = {}
      state.connectStatus = CONNECTION_STATUS.DISCONNECT
      state.targetToken = ''
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setUserDisconnectRTC: (state, { payload }) => {
      state.rtcData = {}
      state.connectStatus = CONNECTION_STATUS.DISCONNECT
    },
    setConnectStatus: (state, { payload }) => {
      state.connectStatus = payload.connectStatus
      state.isCountdowning = false
    },
    setIsSub: (state, { payload }) => {
      state.rtcData.isSub = payload.isSub
    },
    setLocalStream: (state, { payload }) => {
      state.localStream = payload.localStream
    },
    setAudience: (state, { payload }) => {
      state.audience = { ...state.audience, ...payload.audience }
    },
    setIsKickoff: (state, { payload }) => {
      state.isKickoff = payload.isKickoff
    },
    setIsRoomIdChanged: (state, { payload }) => {
      state.isRoomIdChanged = payload.isRoomIdChanged
    },
    setIsNetworkError: (state, { payload }) => {
      state.isNetworkError = payload.isNetworkError
    },
    setReportInfo: (state, { payload }) => {
      state.reportInfo = { ...state.reportInfo, ...payload.reportInfo }
    },
    setRtcStats: (state, { payload }) => {
      state.rtcStats = { ...state.rtcStats, ...payload }
    },
    setNetworkStatus: (state, { payload }) => {
      state.networkStatus = { ...state.networkStatus, ...payload.networkStatus }
      if ((state.networkStatus.rtt || 0) >= 400 || (state.networkStatus.lostRate || 0) >= 20) {
        state.networkStatus.quality = NETWORK_STATUS_QUALITY.BAD
      } else if ((state.networkStatus.rtt || 0) >= 200 || (state.networkStatus.lostRate || 0) >= 10) {
        state.networkStatus.quality = NETWORK_STATUS_QUALITY.NORMAL
      } else {
        state.networkStatus.quality = NETWORK_STATUS_QUALITY.GOOD
      }
    },
    setIsShowUserOutPopup: (state, { payload }) => {
      state.isShowUserOutPopup = payload.isShowUserOutPopup
    },
    getSilentUserList: (state, { payload }) => {
      state.silentUserList = payload.silentUserList
    },
    setSilentUserList: (state, { payload }) => {
      state.silentUserList = [...state.silentUserList, payload.silentUser]
    },
    removeSilentUserList: (state, { payload }) => {
      const selectedIndex = state.silentUserList.indexOf(payload.silentUser)

      const cloneSilentUserList = cloneDeep(state.silentUserList)

      const newSilentUserList = [
        ...cloneSilentUserList.slice(0, selectedIndex),
        ...cloneSilentUserList.slice(selectedIndex + 1, cloneSilentUserList.length),
      ]

      state.silentUserList = newSilentUserList
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRoomUserListByUidThunk.fulfilled, (state, action) => {
      const userList = action?.payload

      const getUserByUid = (item: any) => {
        const user = find(userList, { uid: item.uid })

        return user
      }

      state.handUpList = state.handUpList.map((item) => {
        const user = getUserByUid(item)

        const extra = !!item.extra && JSON.parse(item.extra)

        return {
          ...item,
          type: WS_EVENT_LIVE_SHOW.handup,
          headshotUrl: user?.headshotUrl || item?.headshotUrl,
          refCode: user?.refCode || item?.refCode,
          name: extra.name || user?.name,
        }
      })

      state.micOnList = state.micOnList.map((item) => {
        const user = getUserByUid(item)

        return {
          ...item,
          type: WS_EVENT_LIVE_SHOW.connect,
          headshotUrl: user?.headshotUrl || item?.headshotUrl,
          refCode: user?.refCode || item?.refCode,
          name: item.name || user?.name,
        }
      })
    })
    builder.addCase(fetchRoomUserListByUidThunk.rejected, (state) => {
      state.handUpList = state.handUpList.map((item) => {
        return {
          ...item,
          type: WS_EVENT_LIVE_SHOW.handup,
          headshotUrl: '',
          refCode: '',
          name: '',
        }
      })

      state.micOnList = state.micOnList.map((item) => {
        return {
          ...item,
          type: WS_EVENT_LIVE_SHOW.connect,
          headshotUrl: '',
          refCode: '',
          name: '',
        }
      })
    })
  },
})

export const {
  setLikeList,
  resetLikeList,
  setChathistory,
  setMessageList,
  setNotificationList,
  resetSocketData,
  resetChatData,
  setSocketUrl,
  resetSocket,
  setSocketStatus,
  setReady,
  setHostData,
  setState,
  setLike,
  setUserCount,
  updateUserOnPubInfo,
  resetUserOnPubInfo,
  updateHandUpStatus,
  updateHandUpList,
  updateMicOnList,
  setCountdownSecond,
  setIsCountdowning,
  resetCountdownSecond,
  updateTargetToken,
  addHandUpItem,
  removeHandUpItem,
  addMicOnItem,
  removeMicOnItem,
  setParticipantList,
  setHostDisconnectUser,
  setUserDisconnectRTC,
  setRoomHandUpClose,
  setRoomHandUpStatus,
  setHostUnSub,
  setUserOffByHostOut,
  setHostConnectUser,
  setConnectStatus,
  setIsSub,
  setLocalStream,
  setAudience,
  setIsKickoff,
  setIsRoomIdChanged,
  setIsNetworkError,
  setReportInfo,
  setRtcStats,
  setNetworkStatus,
  setIsShowUserOutPopup,
  getSilentUserList,
  setSilentUserList,
  removeSilentUserList,
} = webSocketSlice.actions
export default webSocketSlice.reducer
