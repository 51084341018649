import { GtmClickRoomListTabType } from './gtmEvent'

export const ROOM_LIVE_TYPE = {
  DEFAULT: 0,
  CURRENT: 1,
  FUTURE: 2,
  PAST: 3,
}

export const LIVE_SOURCE_TYPE = {
  DEFAULT: 0,
  LIVE: 1,
  MP4: 2,
}

export const NOTIFICATION_ACTION = {
  USER_IN: 'user-in',
  PURCHASE: 'purchase',
}

export const FACINGMODE_OPTIONS: string[] = ['user', 'environment']

export const NETWORK_STATUS_QUALITY = {
  GOOD: 'good',
  NORMAL: 'normal',
  BAD: 'bad',
}

export const NETWORK_STATUS_QUALITY_LABEL = {
  [NETWORK_STATUS_QUALITY.GOOD]: 'Live_Network_Status_Good',
  [NETWORK_STATUS_QUALITY.NORMAL]: 'Live_Network_Status_Normal',
  [NETWORK_STATUS_QUALITY.BAD]: 'Live_Network_Status_Bad',
}

export const LIVE_ROOM_TAB_TYPE = {
  LIVE: 'LIVE',
  PAST: 'PAST',
}

export const liveTabsData = [
  {
    tabKey: 'LIVE',
    label: 'Common_Tab_Live',
    value: [ROOM_LIVE_TYPE.CURRENT, ROOM_LIVE_TYPE.FUTURE],
    gtmClickRoomListType: GtmClickRoomListTabType.LIVE,
  },
  {
    tabKey: 'PAST',
    label: 'Common_Tab_Past',
    value: [ROOM_LIVE_TYPE.PAST],
    gtmClickRoomListType: GtmClickRoomListTabType.VIDEO,
  },
]
