import { useState, useEffect } from 'react'

// Libs

// Constants

// Types

export const useIsCSR = () => {
  const [isCSR, setIsCSR] = useState(false)

  useEffect(() => {
    setIsCSR(true)
  }, [])

  return { isCSR }
}
