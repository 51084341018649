// Room Type
export const NORMALROOM = 1
export const LOBBYROOM = 2
export const CONFERENCEROOM = 3
export const LOBBYROOM_WOWZA = 4
export const LOBBYROOM_KURENTOTREE = 5
export const P2PROOM = 6
export const PLAYBACK = 7
export const SINGLE_ROOM = 9 //单向的小班课 只有顾问有视频
export const DEMO_SINGLE_ROOM = 10
export const VC_ROOM = 12 //vc课

// Role Type
export const STUDENT = 10
export const AUDIENCE = 11 //观众
export const CONSULTANT = 20
export const IT = 30
export const GUEST = 90 //游客

//
export const FREE = 95 //免费

// -- Session Status --
export const SESSION_ABC = 2
export const SESSION_JR = 4
export const SESSION_TUTOR_ABC = 1
export const SESSION_TUTOR_JR = 3
export const SESSION_NEW_TUTOR_JR = 33
export const SESSION_JAPAN_JR = 8
export const SESSION_TUTOR_MING = 5
export const SESSION_GLBY = 7
// 微職人
export const SESSION_TW_WEIZHIREN = 80
// -- Session Status --

// Company
export const COMPANY_GROUP = {
  yonglin: 2052,
}

// 企業號
// 永齡
export const YongLinCompanyId = 2052

//课程类型
export const SESSION_TYPE_PRACTICE = 9999 //练习教室
export const SESSION_TYPE_INTERVIEW = 9998 //顾问招聘（面试）
export const SESSION_TYPE_INTRODUCE = 9997 //顾问招聘（自我介绍）

export const SESSION_NAME = {
  [SESSION_TUTOR_ABC]: 'tutorABC',
  [SESSION_ABC]: 'vipABC',
  [SESSION_TUTOR_JR]: 'tutorJr',
  [SESSION_JR]: 'vipJr',
  [SESSION_TUTOR_MING]: 'tutorMing',
  [SESSION_GLBY]: 'columbia',
  [SESSION_JAPAN_JR]: 'japan',
  [SESSION_NEW_TUTOR_JR]: 'newTutorJr',
}
export const DeskTopClientCode = 'itutorgroup'

export const REDAY_NO_OPEN = 1
export const OPEN_WAIT_ENTER = 2
export const ENTER_RECORDING = 3
export const SESSION_END = 4
export const SESSION_KICKOFF = 5
export const SESSION_LAIDOFF = 6

export const SIDE_LEFT_MIN_MULTICOLUMNS_WIDTH = 390

export const STREAM_TYPE = {
  SCREEN: 'SCREEN',
  NORMAL: '',
}

export const isJR = (brandID) => brandID === SESSION_JR || brandID === SESSION_TUTOR_JR || brandID === SESSION_NEW_TUTOR_JR

export const isThemedCompany = (companyId) => Object.values(COMPANY_GROUP).indexOf(companyId) !== -1
export const isYonglin = (companyId) => companyId === COMPANY_GROUP.yonglin

// 成人云讲堂（TutorABC、免费、lobby）isJR 打开
export const isDanmu = (brandID, sstNumber, roomType) => {
  return (brandID === SESSION_TUTOR_ABC || brandID === SESSION_ABC || isJR(brandID)) && sstNumber === FREE && roomType === LOBBYROOM
}

// 开启打赏
export const isEnableReward = (brandID, role) => {
  if (role === CONSULTANT) {
    return true
  } else if (brandID === SESSION_JR) {
    return true
  }
  return false
}

export const ROLE_TYPE = {
  10: STUDENT,
  11: AUDIENCE,
  20: CONSULTANT,
  30: IT,
  90: GUEST,
}

export const ROLE_TYPE_NAME = {
  [CONSULTANT]: 'Consultant',
  [IT]: 'IT',
  [STUDENT]: 'Student',
  [AUDIENCE]: 'Audience',
  [GUEST]: 'Guest',
}

export const CAMERA_STATUS = {
  DISABLE: -99,
  NOT_ALLOWED: -90,
  ERROR: -80,
  REQUEST_CLOSE: -2,
  CLOSE: -1,
  REQUEST: 0,
  OPEN: 1,
  REQUEST_OPEN: 2,
}

export const MIC_STATUS = {
  DISABLE: -99, //没有麦克风
  NOT_ALLOWED: -90, //浏览器权限禁用
  ERROR: -80, //设备有故障
  LOW_VOLUME: -70, //麦克风音量过低
  CLOSE: -1,
  REQUEST: 0,
  OPEN: 1,
}

export const MIC_VOL = {
  MAX_VOL: 100,
  MIN_VOL: 0,
}

export const CANDRAW_STATUS = {
  CLOSE: -1,
  REQUEST: 0,
  OPEN: 1,
}

// Help Issue State
export const HELP_ISSUE_STATE = {
  INITIAL: 'INITIAL',
  CONFIRM: 'CONFIRM',
  ASK_RESOLVED: 'ASK_RESOLVED',
  RESOLVED: 'RESOLVED',
  CLOSED: 'CLOSED',
}

export const MATERIAL_TYPE_SUPPORT_LIST = [
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/pdf',
  'image/png',
  'image/jpeg',
  'application/zip',
  'application/octet-stream',
]

// Language Type
export const LANGUAGE = {
  DEFAULT: 'DEFAULT',
  TW: 'TW',
  CN: 'CN',
  EN: 'EN',
  JA: 'JA',
}

// Global LocalStorage KeyMap
export const LSKEYS = {
  HAD_ENTER_ROOM: 'HAD_ENTER_ROOMv1.0',
  IS_ROOM_SHARED: 'IS_ROOM_SHARED',
  IS_FORBIDDEN_CHAT: 'IS_FORBIDDEN_CHAT',
  CURRENT_LANGUAGE: 'CURRENT_LANGUAGE',
  IS_USER_GUIDE_SHOWED: 'IS_USER_GUIDE_SHOWED', //新手导航是否出现过
  AUDIOID: 'QVVESU9JRA==',
  VIDEOID: 'VklERU9JRA==',
  IS_UPDATE_DEVICE: 'SVNfVVBEQVRFX0RFVklDRQ==',
  REMOTE_MASTER_TOKEN: 'REMOTE_MASTER_TOKEN',
  IS_OPEN_REMOTE: 'IS_OPEN_REMOTE',
  TALKING_PERCENT: 'TALKING_PERCENT',
  TALKING_MINUTES: 'TALKING_MINUTES',
  TALKING_SECONDS: 'TALKING_SECONDS',
  IS_DEVICE_PERMIT_SHOWED: 'IS_DEVICE_PERMIT_SHOWED',
  IS_CUSTOMIZE_VOLUME: 'IS_CUSTOMIZE_VOLUME',
  AGC: 'AGC',
  LOGIN_MUSIC: 'LOGIN_MUSIC',
  VOL_TIP: 'VOL_TIP',
  HEART_BEAT_FLAG: 'HEART_BEAT_FLAG',
  SESSION_REPORT: 'SESSION_REPORT',
  MATERIAL_TAGS: 'MATERIAL_TAGS',
  HAD_CHECKED_BEFORE_HAND_UP: 'HAD_CHECKED_BEFORE_HAND_UP', //举手前的教室检测
  FULLSCREEN: 'FULLSCREEN',
  HAD_SENT_GIFT: 'HAD_SENT_GIFT', //是否送过礼物(顾问端)
  CAN_OPEN_GIFT: 'CAN_OPEN_GIFT', //是否可以抽奖(观众端)
  HAD_OPENED_GIFT: 'HAD_OPENED_GIFT', //是否已经抽过奖(观众端)
  HAD_GOT_GIFT_RESULT: 'HAD_GOT_GIFT_RESULT', //是否已经收到抽奖结果(观众端)
  PLAYBACK_VIEW_RECORD: 'PLAYBACK_VIEW_RECORD',
  CHAT_REPORT_COUNT: 'CHAT_REPORT_COUNT',
  ALL_SILENT_MODAL_DO_NOT_SHOW_AGAIN: 'ALL_SILENT_MODAL_DO_NOT_SHOW_AGAIN',
}

// Download chrome link
export const DOWNLOAD_CHROME_URL = {
  MAC_DOWNLOAD_URL: 'https://www5-stage.tutormeet.com/srdownload/chrome/googlechrome.dmg',
  WINDOW_DOWNLOAD_URL: 'https://www5-stage.tutormeet.com/srdownload/chrome/ChromeSetup.exe',
}

export const PIX_ABAY_URL_HTTP = 'http://pixabay.com'
export const PIX_ABAY_URL_HTTPS = 'https://pixabay.com'

// Download chrome link
export const DOWNLOAD_SUPPORT_CLIENT_URL = {
  //vjr
  // MAC_DOWNLOAD_URL_VJR: 'https://source.pahx.com/ext/app/tutorabc/download/vipjrRemoteSupport.dmg',
  // WINDOW_DOWNLOAD_URL_VJR: 'https://source.pahx.com/ext/app/tutorabc/download/vipjrRemoteSupport.exe',
  MAC_DOWNLOAD_URL_VJR: 'https://get.teamviewer.com/v11/rwuhk54',
  WINDOW_DOWNLOAD_URL_VJR: 'https://get.teamviewer.com/v11/rwuhk54',
  //tjr
  // MAC_DOWNLOAD_URL_TJR: 'https://source.tutorabc.com/OfficialSite/Downloads/TutorABCJrRemoteSupport.dmg',
  // WINDOW_DOWNLOAD_URL_TJR: 'https://source.tutorabc.com/OfficialSite/Downloads/TutorABCJrRemoteSupport.exe',
  MAC_DOWNLOAD_URL_TJR: 'https://get.teamviewer.com/v11/v4q6qk6',
  WINDOW_DOWNLOAD_URL_TJR: 'https://get.teamviewer.com/v11/v4q6qk6',
  //v成人
  // MAC_DOWNLOAD_URL_VABC: 'https://source.pahx.com/ext/app/tutorabc/download/TutorabcRemoteSupport.dmg',
  // WINDOW_DOWNLOAD_URL_VABC: 'https://source.pahx.com/ext/app/tutorabc/download/TutorabcRemoteSupport.exe',
  MAC_DOWNLOAD_URL_VABC: 'https://get.teamviewer.com/v11/rwuhk54',
  WINDOW_DOWNLOAD_URL_VABC: 'https://get.teamviewer.com/v11/rwuhk54',
  //T成人
  // MAC_DOWNLOAD_URL_TABC: 'https://source.tutorabc.com/OfficialSite/Downloads/TutorABCRemoteSupport.dmg',
  // WINDOW_DOWNLOAD_URL_TABC: 'https://source.tutorabc.com/OfficialSite/Downloads/TutorABCRemoteSupport.exe',
  MAC_DOWNLOAD_URL_TABC: 'https://get.teamviewer.com/v11/rwuhk54',
  WINDOW_DOWNLOAD_URL_TABC: 'https://get.teamviewer.com/v11/rwuhk54',
}

export const MAILS = ['sendohchen@tutorabc.com', 'yuchichang@tutorabc.com', 'willydu@tutorabc.com', 'jimzhong@tutorabc.com']
// SOCKET Channel
export const SOCKET_CHANNEL = {
  LIVE_CHANNEL: 'TUTORMEETS_LIVE',
  PLAYBACK_CHANNEL: 'TUTORMEETS_PLAYBACK',
}

// TIMER_BAR_MSG
export const PROJ_TIMER_BAR_MSG = [
  'DO NOT ask the clients to introduce themselves',
  'DO correct the clients’ grammar and pronunciation as much as possible',
  'DO write important points and corrections on the whiteboard',
  'DO call on the clients in a specific order and keep track so as to provide equal speaking time',
  "DO check the clients' understanding by asking for questions at the end of every 1 or 2 slides",
  'Do make the reading interactive with discussion questions',
  'DO show interest in what the clients have to say to encourage more input from them',
  'DO give clients feedback about what they need to work on to improve their English',
  'DO smile!',
  'DO use the Emergency button to contact IT if clients are having technical difficulties',
]

//helper

export const customerToCoordinatorInClass = 1

export const customerToItInClass = 2

export const customerToItAfterClass = 3

export const coordinatorToItInClass = 4

//GDDATA URL
export const GDATA_HOST = {
  qa: 'https://www5-qa.tutormeet.com/v2/render/gdata',
  stage: 'https://www5-stage.tutormeet.com/v2/render/gdata',
  prod: 'https://www5.tutormeet.com/v2/render/gdata',
}

export const ZOOM = {
  SMALL: 0.9,
  NOMAL: 1,
  MEDIUM: 1.2,
  BIG: 1.4,
  BIGGER: 1.6,
}

export const WB_AREA_VIEWER = {
  WHITEBOARD: 1,
  SCREENSHARE: 2,
}

export const SCREENSHARE_PLUGIN_URL = 'http://tm-download.oss-cn-shanghai.aliyuncs.com/tools/desktopCapture.zip'
export const DEFAULT_AVATAR = 'https://source.pahx.com/Ext/images/website/member/profile/20170531/6d791e09-94f2-4abb-bd4c-cfb7bd04a100.png'

// export const CLIENT_URL = {
//   OLD_VERSION: `${window.location.origin}/v2/render`,
//   CONSULTANT_NEW_VERSION: `${window.location.origin}/v3/consultant.html`,
//   STUDENT_NEW_VERSION: `${window.location.origin}/v3/student.html`,
// }

export const SCREEN_STATE = {
  STATE_ON: 1,
  STATE_OFF: -1,
}

export const SCREEN_TYPE = {
  RESOLUTION_1: 0,
  RESOLUTION_2: 1,
  RESOLUTION_3: 2,
}

export const SCREEN_RESOLUTION = [
  {
    RESOLUTION_TEXT: '高清:1920 x 1080',
    RESOLUTION_VALUE: 2,
  },
  {
    RESOLUTION_TEXT: '标清:1440 x 900',
    RESOLUTION_VALUE: 1,
  },
  {
    RESOLUTION_TEXT: '流畅:1280 x 800',
    RESOLUTION_VALUE: 0,
  },
]

export const SCREEN_RESOLUTION_MAP = {
  0: { width: '1280px', height: '800px' },
  1: { width: '1440px', height: '900px' },
  2: { width: '1920px', height: '1080px' },
}

export const SCREEN_MSG = {
  SCREEN_MSG_ALL: 1,
  SCREEN_MSG_DEFAULT: 0,
  SCREEN_MSG_HIDDE: -1,
}

export const CHANNEL = {
  STUDENT: 1,
  TEACHER: 2,
}

export const REMOTE_SHOW = {
  FULLSCREEN: 1,
  NORMALSCREEN: 0,
  HIDDENSCREEN: -1,
}

export const REMOTE_STATUS = {
  START: 1,
  CLOSE: 0,
  CANCEl: -1,
}

export const ROOM_STATUS = {
  // -1 == 未开始 | 0 == 已结束 | 1 == 上课中
  NOTSTARTED: -1,
  END: 0,
  BEGINING: 1,
}

export const basicInfo = {
  avatarImageUrl: '',
  isPub: false, //视频是否发布成功
  isSub: 0, // -1 notNecessary 0 hasNot 1 hadSub
  subInfo: {},
  screenAnswer: null, //屏幕分享的subanswer
  answer: null, //pub or sub answer
  answerId: '',
  screenAnswerId: '',
  extra: {
    camera: -1,
    mic: -1,
    whiteboardState: false,
    // localVolume: 0,
    // remoteVolume: 0,
    star: 0,
    os: '',
    canChat: true,
    canDraw: true,
    status: true, //表示离开状态,true表示未离开，false表示离开
    kickoff: false,
    laidoff: false,
  },
  networkStatus: {
    status: -1,
    rtt: 0,
  },
}
export const TIMEOUT = 'TIMEOUT'
export const SUCCESS = 'SUCCESS'
export const ERROR = 'ERROR'
export const FETCHING = 'FETCHING'
export const NOTFOUND = 404
export const GAME_FIGHT = 'GAME_FIGHT'
export const DEFAULT_WS_SCALE = 100
export const MAX_WS_SCALE = 150
export const MIN_WS_SCALE = 75

export const playbackDropCode = -15005

//0: '成功', 1: '无操作权限', 2: '麦序列表满', 3: '举手列表满', 4: '不能重复添加', 5: '请求房间不存在', 6: '请求参数有问题',
export const LIVE_CODE = {
  0: 'lblSendSuccess',
  1: 'lblNoAccessibility', // '无操作权限',
  2: 'lblConnectLimit', // '连麦已满4人，请断开与其他学生连接后再邀请',
  3: 'libHandUpLimit', // '排队的人太多啦，请十秒后再试喔～',
  4: 'lblNoRepeat', // '不能重复添加',
  5: 'lblNoRoom', // '请求房间不存在',
  6: 'lblParamError', // '请求参数有问题',
}
export const BRAND_TYPE = {
  V: 2,
  VJR: 4,
  T: 1,
  TJR: 33,
  TJR_1: 3,
  J: 8,
}

export const DOWNLOAD_AV1_CLIENT = {
  c64: 'https://tmpc.tutormeet.com/win/consultant/av1/iTutor%20Consultant_Setup_x64.exe',
  c32: ' https://tmpc.tutormeet.com/win/consultant/av1/iTutor%20Consultant_Setup.exe',
  s64: 'https://tmpc.tutormeet.com/win/vipjr/av1/iTutor_Setup_x64.exe',
  s32: 'https://tmpc.tutormeet.com/win/vipjr/av1/iTutor_Setup.exe',
}

export const WHITEBOARD_TOOL_CONFIG = 'WHITEBOARD_TOOL_CONFIG'
export const CARTOON_VISIBLE = 'CARTOON_VISIBLE'
export const UPLOAD_MATERIAL_INIT = 'UPLOAD_MATERIAL_INIT'
export const UPLOAD_MATERIAL_TRANSFORM = 'UPLOAD_MATERIAL_TRANSFORM'
export const UPLOAD_MATERIAL_DONE = 'UPLOAD_MATERIAL_DONE'
export const UPLOAD_MATERIAL_FAIL = 'UPLOAD_MATERIAL_FAIL'
export const DANMU_COLORS = ['#FFF', '#FF721F', '#F4D10C', '#6AC900', '#00E9FF', '#039EFF', '#A96EFE', '#FF4B59']
export const DANMU_STATUS = 'DANMU_STATUS'
export const DANMU_CONFIG = 'DANMU_CONFIG'
export const TIME_LIMIT = 'TIME_LIMIT'

//material type
export const DYNC_MATERIAL = 2
export const STATIC_MATERIAL = 1

//1=macos， 2=ios ，3=android， 4=windows, 5=web
export const LOG_FROM = {
  notfound: {
    device: 'notfound',
    from: 0,
  },
  mac: {
    device: 'mac',
    from: 1,
  },
  ios: {
    device: 'ios',
    from: 2,
  },
  android: {
    device: 'android',
    from: 3,
  },
  windows: {
    device: 'windows',
    from: 4,
  },
  web: {
    device: 'web',
    from: 5,
  },
  others: {
    device: 'others',
    from: 6,
  },
}

export const TIME_ALIGN = {
  diffSec: 4,
  diffMs: 2000,
}
