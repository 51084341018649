import { createSlice } from '@reduxjs/toolkit'

// Constants

interface ICreateOrder {
  orderId: string
  orderNo: string
  payType: string
  validateDate: string
  createdAt: string
  totalAmount: number
  paymentAmount: number
}

export interface IInitialState {
  createOrder: ICreateOrder
}

const initialState: IInitialState = {
  createOrder: {
    orderId: '',
    orderNo: '',
    payType: '',
    validateDate: '',
    createdAt: '',
    totalAmount: 0,
    paymentAmount: 0,
  },
}

export const orderResultSlice = createSlice({
  name: 'orderResult',
  initialState,
  reducers: {
    setCreateOrderData: (state, { payload }) => {
      state.createOrder = payload
    },
    resetCreateOrderData: (state) => {
      state.createOrder = initialState.createOrder
    },
  },
})

export const { setCreateOrderData, resetCreateOrderData } = orderResultSlice.actions
export default orderResultSlice.reducer
