import { useState, useRef, useEffect } from 'react'
import classnames from 'classnames/bind'
import { useMutation } from 'react-query'
import { useRouter } from 'next/router'
import { toast } from 'react-toastify'

// Libs
import { setDebugReport } from '@/api/setDebugReport'

// Constants

// Components
import Icon from '@/assets/icons'
import DebugItem from './components/DebugItem'

// Styles
import styles from './style.module.scss'

// Variables
const cx = classnames.bind(styles)

type DebugProps = {
  logList: any[]
}

const MAX_REPORT_LENGTH = 300

const Debug = (props: DebugProps) => {
  const { logList } = props

  const [canTouch, setCanTouch] = useState(true)
  const [isOpen, setIsOpen] = useState(true)

  const debugRef = useRef<any>(null)

  const router = useRouter()
  const { query } = router

  const setDebugReportMutation = useMutation(setDebugReport)

  useEffect(() => {
    if (debugRef.current) {
      debugRef.current.scrollTop = debugRef.current.scrollHeight
    }
  }, [logList])

  const handleDebugReport = async () => {
    setDebugReportMutation
      .mutateAsync({ logList: logList.slice(0, MAX_REPORT_LENGTH), roomId: query.roomId as string })
      .then(() => toast.success('回報成功'))
      .catch(() => toast.error('回報失敗'))
  }

  return (
    <div className={cx('debug-wrapper')}>
      <div className={cx('debug-action', 'touch')} onClick={() => setCanTouch((prev) => !prev)}>
        <div className={cx('debug-action-icon', 'touch')}>{canTouch ? <Icon.EyeOpen /> : <Icon.EyeClose />}</div>
      </div>

      <div className={cx('debug-action', 'close')} onClick={() => setIsOpen((prev) => !prev)}>
        <div className={cx('debug-action-icon', 'close')}>{isOpen ? <Icon.Close /> : <Icon.Pin />}</div>
      </div>

      <div className={cx('debug-action', 'trend')} onClick={handleDebugReport}>
        <div className={cx('debug-action-icon', 'trend')}>{<Icon.Trend />}</div>
      </div>

      {isOpen && (
        <div ref={debugRef} className={cx('debug', { canTouch })}>
          {logList.map((item, index) => (
            <DebugItem key={index} {...item} />
          ))}
        </div>
      )}
    </div>
  )
}

export default Debug
