import handleLocationHrefDirect from '@/methods/url/handleLocationHrefDirect'
import { isClientSideShopApp } from '@/utils/navigator'
import { execute } from '@tutorabc/webview-jsbridge'

// Constants
import { STORAGE_DATA } from '@/constants/storageData'
import { envConfigs } from '@/constants/config'
import { ROUTE_PATH } from '@/constants/routePath'
import { BRIDGE_HANDLER } from '@/constants/bridgeHandler'

type ParamsType = {
  router: any
}

const handleLogout = ({ router }: ParamsType) => {
  window.localStorage.removeItem(STORAGE_DATA.TOKEN)
  window.localStorage.removeItem(STORAGE_DATA.IS_TIMEOUT)
  window.localStorage.removeItem(STORAGE_DATA.SHOPPINGCART_ID)

  const isStrawberry = router.pathname === ROUTE_PATH.STRAWBERRY_LIVE_ROOM

  // 全站登出都回首頁 http://tp-jira.tutorabc.com/browse/SHOP-154
  const returnUrl = window.location.search
    ? `${window.location.origin}${ROUTE_PATH.HOME}${window.location.search}`
    : `${window.location.origin}${ROUTE_PATH.HOME}`

  const url = `${envConfigs?.logOutUrl}?returnUrl=${returnUrl}`

  const strawberryUrl = 'https://www.strawberrynet.com'

  if (isClientSideShopApp) {
    execute({
      handlerName: BRIDGE_HANDLER.TOKEN_EXPIRED,
      data: {},
    })
  } else {
    handleLocationHrefDirect({ router, url: isStrawberry ? strawberryUrl : url })
  }
}

export default handleLogout
