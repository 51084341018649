import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import classNames from 'classnames/bind'
import { useTranslation } from 'next-i18next'
import { isNull, isUndefined } from 'lodash'

// Libs
// import useSearchData from '@/hooks/useSearchData'
import useMedia from '@/hooks/useMedia'
import useSearchHotKey from '@/hooks/useSearchHotKey'
import handleRoutePushWithQuery from '@/methods/url/handleRoutePushWithQuery'
import useAutoCompleteAction from '@/hooks/useAutoCompleteAction'

// Components
import Icon from '@/assets/icons'
import InputComponent from '@/components/common/Input/index'
import useSearchHistory from '@/hooks/useSearchHistory'
import Option from '@/components/shared/SearchInput/components/Option'
import HotSearchKeywordList from '@/components/shared/HotSearchKeywordList'
// import MallList from '@/components/shared/MallList'

// Constants
import { ROUTE_PATH } from '@/constants/routePath'
import { SEARCH_TYPE } from '@/constants/search'

// Styles
import styles from './style.module.scss'

const cx = classNames.bind(styles)

interface IHeaderSearchInput {
  setIsLogoHidden: any
  setIsSearchInputOpen: any
}

const HeaderSearchInput = ({ setIsLogoHidden, setIsSearchInputOpen }: IHeaderSearchInput) => {
  const router = useRouter()
  const {
    handleKeyPress,
    handleKeyDown,
    autoCompleteKeyword,
    setAutoCompleteKeyword,
    autoCompleteIsOpen,
    setAutoCompleteIsOpen,
    autoCompleteSelectIndex,
    setAutoCompleteSelectIndex,
    inputRef,
  } = useAutoCompleteAction()
  const [isFocus, setIsFocus] = useState(false)
  const { isDesktop, isMobile, isTablet } = useMedia()
  const { setHistoryLocalStorage, historyLocalStorage } = useSearchHistory()
  const { t } = useTranslation()
  const { hotKeyData, keywordList, filterKeywordList = [] } = useSearchHotKey({ autoCompleteKeyword })

  // TODO: 會影響到是否要顯示推薦商品，目前先註解掉，有可能還會再開

  // 先按照字短的往前排
  // const initialHotKeyword = hotKeyData?.keywords
  //   .filter((item) => item.keyword.length > 1)
  //   .sort((a, b) => a.keyword.length - b.keyword.length)[0]?.keyword
  // const { searchData, isSearchDataFetched, isSearchDataSuccess } = useSearchData({
  //   keyword: initialHotKeyword || '',
  // })
  // const categoryListData = {
  //   data: {
  //     pages: [{ productsInfo: searchData?.product?.slice(0, 10) }],
  //   },
  //   isFetchingNextPage: false,
  //   fetchNextPage: () => {},
  //   isFetched: isSearchDataFetched,
  //   isSuccess: isSearchDataSuccess,
  // }

  const handleFocus = () => {
    if (!isFocus) {
      setIsFocus(true)
      setIsSearchInputOpen(true)
      if (!isDesktop) {
        setIsLogoHidden(true)
      }
    }
  }
  const handleOutSideClick = (event: MouseEvent) => {
    if (!(inputRef.current && inputRef.current.contains(event.target))) {
      setAutoCompleteIsOpen(false)
      setIsFocus(false)
      setIsSearchInputOpen(false)
      setAutoCompleteSelectIndex(null)
      setIsLogoHidden(false)
    }
  }

  const showSearchInputField = () => {
    if (isDesktop) {
      return true
    } else {
      return autoCompleteIsOpen
    }
  }

  useEffect(() => {
    const initInput = () => {
      setAutoCompleteIsOpen(false)
      setIsFocus(false)
      setIsSearchInputOpen(false)
      setAutoCompleteSelectIndex(null)
    }
    initInput()
    document.addEventListener('click', handleOutSideClick)

    return () => {
      document.removeEventListener('click', handleOutSideClick)
      setIsFocus(false)
      setIsSearchInputOpen(false)
    }
  }, [])

  useEffect(() => {
    if (isFocus) {
      document.body.style.overflow = 'hidden'
      if (!isDesktop) {
        setIsLogoHidden(true)
      }
    }

    return () => {
      if (isFocus) document.body.style.overflow = 'initial'
      setIsLogoHidden(false)
    }
  }, [isFocus])

  const handleMobileBackIconClick = () => {
    setAutoCompleteKeyword('')
    setIsFocus(false)
    setIsSearchInputOpen(false)
  }
  return (
    <>
      {/* 透過 CSS 讓 mobile 情境的 input field 不能按 */}
      <div
        className={cx('search-input', { focus: isFocus, 'search-input--close-search-input-field': (isTablet || isMobile) && !autoCompleteIsOpen })}
        ref={inputRef}
      >
        {isDesktop && (
          <div
            className={cx('search-input-icon')}
            onClick={() => {
              setIsFocus(true)
              setIsSearchInputOpen(true)
            }}
          >
            <Icon.SearchMagnifier />
          </div>
        )}
        {isFocus && !isDesktop && (
          <div className={cx('search-input-icon-back', { desktop: isDesktop })} onClick={handleMobileBackIconClick}>
            <Icon.ArrowLeft />
          </div>
        )}
        <div style={{ width: '100%' }}>
          {showSearchInputField() && (
            <InputComponent
              onKeyDown={handleKeyDown}
              className={cx('search-input-text', { 'search-input-text--plain': isFocus && (isTablet || isMobile) })}
              wrapperClassName={cx('search-input-text-wrapper', { 'search-input-text-wrapper--plain': isFocus && (isTablet || isMobile) })}
              placeholder={
                !isUndefined(hotKeyData?.keywords) && !isNull(hotKeyData.keywords) && hotKeyData?.keywords[0]?.keyword
                  ? `${t('Search_Header_Placholder')} ${hotKeyData?.keywords[0]?.keyword || ''}`
                  : ''
              }
              onChange={(e) => {
                setAutoCompleteIsOpen(true)
                setAutoCompleteKeyword(e.target.value)
                setAutoCompleteSelectIndex(null)
              }}
              autoFocus={isFocus}
              value={autoCompleteKeyword as string}
              onFocus={handleFocus}
              onKeyPress={handleKeyPress}
            />
          )}
        </div>
        {isFocus && autoCompleteKeyword.length > 0 && (
          <div className={cx('search-input-icon-clear', { desktop: isDesktop })} onClick={() => setAutoCompleteKeyword('')}>
            {isDesktop ? <Icon.Close /> : <Icon.InputClear />}
          </div>
        )}
        {(isFocus || autoCompleteIsOpen) &&
          (autoCompleteKeyword.trim().length > 0 ? (
            autoCompleteIsOpen && (
              <div className={cx('search-input__auto-complete')}>
                {filterKeywordList.map((item: any, index: number) => (
                  <Option
                    keyword={item.keyword}
                    key={index}
                    handleClick={() => {
                      setAutoCompleteIsOpen(false)
                      setAutoCompleteKeyword(item.keyword)
                      setHistoryLocalStorage(item.keyword)
                      handleRoutePushWithQuery({
                        router,
                        pathname: ROUTE_PATH.SEARCH,
                        query: { keyword: item.keyword, type: SEARCH_TYPE.ALL },
                      })
                    }}
                    active={index === autoCompleteSelectIndex}
                  />
                ))}
              </div>
            )
          ) : (
            <div className={cx('search-input__auto-complete')}>
              <div className={cx('search-input__auto-complete-history')}>
                {historyLocalStorage.length > 0 && <HotSearchKeywordList data={historyLocalStorage} displayAmount={8} title={t('Search_History')} />}
                <HotSearchKeywordList displayAmount={8} data={keywordList} />
                {/* TODO: 會影響到是否要顯示推薦商品，目前先註解掉，有可能還會再開 */}

                {/* {searchData?.product?.length > 0 && !isDesktop && (
                <>
                  <div className={cx('search-input__auto-complete-history-title')}>推薦商品</div>
                  <MallList categoryListData={categoryListData} />
                </>
              )} */}
              </div>
            </div>
          ))}
        {(isTablet || isMobile) && (
          <div
            className={cx('search-input-icon')}
            onClick={() => {
              if (!isFocus) {
                setAutoCompleteIsOpen(true)
                setIsFocus(true)
                setIsSearchInputOpen(true)
                setIsLogoHidden(true)
              } else {
                setAutoCompleteIsOpen(false)
                setIsFocus(false)
                setIsSearchInputOpen(false)
                setIsLogoHidden(false)
              }
            }}
          >
            <Icon.SearchMagnifier />
          </div>
        )}
      </div>
      {<div className={cx('search-input__auto-complete-mask', { active: isDesktop && isFocus })} />}
    </>
  )
}
export default HeaderSearchInput
