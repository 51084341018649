import { ofType } from 'redux-observable'
import { mergeMap } from 'rxjs/operators'
import { EMPTY } from 'rxjs'

import { setHostConnectUser } from '@/redux/reducers/socket'

// Libs
import { handleUserConnectStatus } from '@/methods/message/handleUserConnectStatus'

export const setHostConnectUserAction = ({ action$, state }: any) => {
  return action$.pipe(
    ofType(setHostConnectUser.type),
    mergeMap((action2: any) => {
      const { payload } = action2
      const socketData = state.value.socket
      const { socket } = socketData

      handleUserConnectStatus({ socket, uid: payload.targetToken, isConnect: true, name: payload?.name })

      return EMPTY
    }),
  )
}
