export const CALENDAR_DEFAULT_PAGE_SIZE = 20

interface IMonthsEnglishName {
  [key: string]: string
}

export const MONTHS_ENGLISH_NAME: IMonthsEnglishName = {
  '1': 'January',
  '2': 'February',
  '3': 'March',
  '4': 'April',
  '5': 'May',
  '6': 'June',
  '7': 'July',
  '8': 'August',
  '9': 'September',
  '10': 'October',
  '11': 'November',
  '12': 'December',
}
