import { createSlice } from '@reduxjs/toolkit'

export interface IInitialState {
  isHeaderFixed: boolean
  isHeaderBottomShowed: boolean
}

const initialState: IInitialState = {
  isHeaderFixed: true,
  isHeaderBottomShowed: false,
}

export const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setHeaderIsHeaderFixed: (state, { payload }) => {
      const { data } = payload

      state.isHeaderFixed = data
    },
    setHeaderIsHeaderBottomShowed: (state, { payload }) => {
      const { data } = payload

      state.isHeaderBottomShowed = data
    },
  },
})

export const { setHeaderIsHeaderFixed, setHeaderIsHeaderBottomShowed } = headerSlice.actions

export default headerSlice.reducer
