import classnames from 'classnames/bind'
import { format } from 'date-fns'

// Libs

// Components

// Types

// Styles
import styles from './style.module.scss'

const cx = classnames.bind(styles)

type DebugItemProps = {
  ts: number
  level?: string
  data: string
}

const DebugItem = (props: DebugItemProps) => {
  const { ts, level, data } = props

  //const newData = Object.values(JSON.parse(data))

  return (
    <div className={cx('item')} data-type={level}>
      <p>{format(new Date(ts), 'yyyy/MM/dd HH:mm')}</p>
      {data && (
        <div className={cx('item-data')}>
          <span className={cx('item-data-item')}>{data}</span>
        </div>
      )}
    </div>
  )
}

export default DebugItem
