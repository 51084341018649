import { createSlice } from '@reduxjs/toolkit'

// Libs

// Constant

// Types
import { RoomDataType } from '@/types/room'

// Thunk

interface RoomStateType {
  gdata: any
  roomData: RoomDataType
  soundValue: number
}

const initialState: RoomStateType = {
  gdata: {},
  roomData: {
    roomId: '',
    clientSn: 0,
    coverPhotoUrl: '',
    dummyViews: 0,
    endTime: '',
    kolFirstEntry: '',
    kolHeadshotUrl: '',
    kolName: '',
    likes: 0,
    liveType: 0,
    memberInfoId: '',
    roomDesc: '',
    roomPlacard: '',
    sessionRoomId: '',
    startTime: '',
    tickerTape: [],
    title: '',
    liveUrlInfo: [],
    messageListCursor: null,
    likeListCursor: null,
    hasPlayback: false,
    playbackData: {},
    quality: '',
  },
  soundValue: 0,
}

export const roomSlice = createSlice({
  name: 'room',
  initialState,
  reducers: {
    setRoomGData: (state, { payload }) => {
      state.gdata = payload.gdata
    },
    setRoomData: (state, { payload }) => {
      state.roomData = { ...state.roomData, ...payload.roomData }
    },
    resetRoomData: () => {
      return initialState
    },
    setMessageListCursor: (state, { payload }) => {
      state.roomData.messageListCursor = payload
    },
    setLikeListCursor: (state, { payload }) => {
      state.roomData.likeListCursor = payload
    },
    setLiveType: (state, { payload }) => {
      state.roomData.liveType = payload.liveType
    },
    setPlaybackData: (state, { payload }) => {
      state.roomData.playbackData = payload.playbackData
    },
    setHasPlayback: (state, { payload }) => {
      state.roomData.hasPlayback = payload.hasPlayback
    },
    setSoundValue: (state, { payload }) => {
      state.soundValue = payload.soundValue
    },
    setQuality: (state, { payload }) => {
      state.roomData.quality = payload.quality
    },
  },
})

export const {
  setRoomGData,
  setRoomData,
  resetRoomData,
  setMessageListCursor,
  setLikeListCursor,
  setLiveType,
  setPlaybackData,
  setHasPlayback,
  setSoundValue,
  setQuality,
} = roomSlice.actions

export default roomSlice.reducer
