import { createSlice } from '@reduxjs/toolkit'

export interface IInitialState {
  productCardWidth: number
}

const initialState: IInitialState = {
  productCardWidth: 0,
}

export const recentLiveStreamingSlice = createSlice({
  name: 'recent-live-streaming',
  initialState,
  reducers: {
    setProductCardSize: (state, { payload }) => {
      const { width } = payload
      state.productCardWidth = width
    },
  },
})

export const { setProductCardSize } = recentLiveStreamingSlice.actions

export default recentLiveStreamingSlice.reducer
