import { hasCamel } from './regexes'
/**
 * Convert a `string` to camel case.
 *
 * @param {String} string
 * @return {String}
 */

function toUpperCamelCase(string: string) {
  if (hasCamel.test(string)) {
    return `${string.substring(0, 1).toUpperCase()}${string.substring(1)}`
  } else {
    return string
  }
}

export default toUpperCamelCase
