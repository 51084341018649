// import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useRouter } from 'next/router'

// Libs
import { fetchPlatformAnnouncements, IPlatformAnnouncementsData } from '@/api/fetchPlatformAnnouncements'

// Constants
import { QUERY_KEY } from '@/constants/queryKey'

const useAnnouncements = (): IPlatformAnnouncementsData[] => {
  const router = useRouter()
  const { locale } = router
  const { data: announcementslist = [] } = useQuery([QUERY_KEY.FETCH_PLATFORM_ANNOUCEMENTS, { locale }], fetchPlatformAnnouncements)
  return announcementslist
}

export default useAnnouncements
