export const EXTERNAL_LINK = {
  CUSTOMER_SERVICE_CENTER: 'https://lin.ee/eNY9yaC',
  EINVOICE_GOVERNMENT_INFORMATION: 'https://www.einvoice.nat.gov.tw/?CSRT=6201288656407980614',
  BECOME_MERCHANT: 'https://landingpage.tutorabcshop.com/LandingPage/MerchantsRecruitment/Index',
  BECOME_KOL: 'https://landingpage.tutorabcshop.com/LandingPage/KOLRecruitment/Index',
  FAQ: 'https://intercom.help/tutorabcshop/zh-TW/',
  TERMS_OF_SERVICE: 'https://intercom.help/tutorabcshop/zh-TW/collections/5712119-%E6%9C%8D%E5%8B%99%E6%A2%9D%E6%AC%BE',
  RETURN_POLICY: 'https://intercom.help/tutorabcshop/zh-TW/collections/5642764-%E9%80%80%E8%B2%A8%E9%80%80%E6%AC%BE',
  RETURN_REFUND_APPLICATION_FORM: 'https://forms.gle/mPSqjaGR2ASNo8WbA',
  CVS_RETURN_INSTRUCTIONS:
    'https://intercom.help/tutorabcshop/zh-TW/articles/8254722-%E9%80%80%E8%B2%A8%E5%95%86%E5%93%81%E5%A6%82%E4%BD%95%E9%80%80%E5%9B%9E%E8%B3%A3%E5%AE%B6',
  OFFLINE_RETURN_APPLICATION_FORM: 'https://docs.google.com/forms/d/e/1FAIpQLSeSGrsLnXF1KfMNehRLLbcvf_JkMhZ7P5l8uWKHyp2sp425RQ/viewform',
}
