import { isEmpty, trimStart } from 'lodash'
import removeSearchParams from '@/methods/url/removeSearchParams'

const getUrlWithNewSearch = ({ searchList = [], url, isLowerCase, searchParams = {} }) => {
  if (!process.browser) return

  const searchParamsList = Object.entries(searchParams).map(([key, value]) => {
    return { key, value }
  })

  const newSearchList = [...searchList, ...searchParamsList]

  // 先把舊的 search 清掉，以免重複
  const removeKeyList = newSearchList.map((item) => item.key)
  const urlByRemoveSearch = removeSearchParams({ removeKeyList, url, isLowerCase })

  const urlObject = new URL(urlByRemoveSearch)
  const searchString = urlObject.search
  const searchStringWithoutQuestionMark = trimStart(searchString, '?')

  const newSearchString = newSearchList
    .filter((item) => item?.value && !isEmpty(String(item.value)))
    .map((item) => `${item.key}=${item.value}`)
    .join('&')

  let finalSearchString = ''

  const hasOldSearchSting = !isEmpty(searchStringWithoutQuestionMark)
  const hasNewSearchSting = !isEmpty(newSearchString)

  switch (true) {
    case hasOldSearchSting && hasNewSearchSting:
      finalSearchString = `?${searchStringWithoutQuestionMark}&${newSearchString}`

      break
    case hasOldSearchSting && !hasNewSearchSting:
      finalSearchString = `?${searchStringWithoutQuestionMark}`

      break
    case !hasOldSearchSting && hasNewSearchSting:
      finalSearchString = `?${newSearchString}`

      break
    case !hasOldSearchSting && !hasNewSearchSting:
      finalSearchString = ''

      break
    default:
      break
  }

  const newUrl = `${urlObject.origin}${urlObject.pathname}${finalSearchString}`

  return newUrl
}

export default getUrlWithNewSearch
