export type StateType = {
  isMajorMenuOpen: boolean
  isSecondaryMenuOpen: boolean
  isDropdowMenuOpen: boolean
  isLanguageListOpen: boolean
  isLogoHidden: boolean
  isBurgerOpen: boolean
}

export enum HeaderActionType {
  HANDLE_LOGO_HIDDEN = 'HANDLE_LOGO_HIDDEN',
  HANDLE_DROPDOWN_CLOSE = 'HANDLE_DROPDOWN_CLOSE',
  HANDLE_MAJOR_OPEN = 'HANDLE_MAJOR_OPEN',
  HANDLE_SECONDARY_OPEN = 'HANDLE_SECONDARY_OPEN',
  HANDLE_LANGUAGE_LIST_OPEN = 'HANDLE_LANGUAGE_LIST_OPEN',
}

export const initialState: StateType = {
  isMajorMenuOpen: false,
  isSecondaryMenuOpen: false,
  isDropdowMenuOpen: false,
  isLanguageListOpen: false,
  isLogoHidden: false,
  isBurgerOpen: false,
}

type ActionType = {
  type: HeaderActionType
} & Partial<StateType>

export const headerReducer = (state: StateType, action: ActionType) => {
  switch (action.type) {
    case HeaderActionType.HANDLE_DROPDOWN_CLOSE:
      return {
        ...state,
        isDropdowMenuOpen: false,
        isMajorMenuOpen: false,
        isSecondaryMenuOpen: false,
        isBurgerOpen: false,
        isLanguageListOpen: false,
      }

    // OPEN 地獄
    case HeaderActionType.HANDLE_MAJOR_OPEN:
      return {
        ...state,
        isMajorMenuOpen: !state.isMajorMenuOpen,
        isSecondaryMenuOpen: false,
        isLanguageListOpen: false,
        isDropdowMenuOpen: !state.isMajorMenuOpen,
        isBurgerOpen: false,
      }
    case HeaderActionType.HANDLE_SECONDARY_OPEN:
      return {
        ...state,
        isSecondaryMenuOpen: !state.isSecondaryMenuOpen,
        isMajorMenuOpen: false,
        isLanguageListOpen: false,
        isDropdowMenuOpen: !state.isSecondaryMenuOpen,
        isBurgerOpen: !state.isSecondaryMenuOpen,
      }
    case HeaderActionType.HANDLE_LANGUAGE_LIST_OPEN:
      return {
        ...state,
        isLanguageListOpen: !state.isLanguageListOpen,
        isMajorMenuOpen: false,
        isSecondaryMenuOpen: false,
        isDropdowMenuOpen: !state.isLanguageListOpen,
        isBurgerOpen: false,
      }

    default:
      return state
  }
}
