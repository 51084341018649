import { useRef } from 'react'
import classnames from 'classnames/bind'

// Libs

// Components
import Lottie from '@/components/common/Lottie'

// Constants

// Lotties
import loadingData from '@/assets/lotties/player-loading.json'
// Styles
import styles from './style.module.scss'

const cx = classnames.bind(styles)

const Loading = ({ isFull = true }: { isFull?: boolean }) => {
  const loadingRef = useRef<any>(null)

  return (
    <div className={cx('loading')} data-is-full={isFull}>
      <div className={cx('loading-lottie')} ref={loadingRef}>
        <Lottie lottieRef={loadingRef} animationData={loadingData} config={{ loop: true, autoplay: true }} />
      </div>
    </div>
  )
}

export default Loading
