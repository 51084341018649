import { createSlice } from '@reduxjs/toolkit'

export interface IInitialState {
  logList: any[]
}

const initialState: IInitialState = {
  logList: [],
}

export const logSlice = createSlice({
  name: 'log',
  initialState,
  reducers: {
    setLog: (state, { payload }) => {
      state.logList.push(payload)
      // if (state.logList.length > 100) {
      //   state.logList.shift()
      // }
    },
    log: (state, { payload }) => {
      state.logList.push({ ts: Date.now(), level: 'LOG', data: payload })
      console.log(payload)
    },
    error: (state, { payload }) => {
      state.logList.push({ ts: Date.now(), level: 'ERROR', data: payload })
      console.error(payload)
    },
  },
})

export const { setLog, log, error } = logSlice.actions

export default logSlice.reducer
