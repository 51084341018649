import React from 'react'
import classNames from 'classnames/bind'

// Libs
import useSearchHotKeyLive from '@/hooks/useSearchHotKeyLive'

// Components
import Insight from '@/components/shared/Insight'

// Constants

// Styles
import styles from './style.module.scss'

const cx = classNames.bind(styles)
interface IOption {
  handleClick: () => void
  active: boolean
  keyword: string
}

const Option = ({ keyword, handleClick, active }: IOption) => {
  const { isLive } = useSearchHotKeyLive({ keyword: keyword })
  return (
    <div className={cx('option', { active })} onClick={handleClick}>
      <p>{keyword}</p>
      {isLive && <Insight status='live' theme='gray' />}
    </div>
  )
}
export default Option
