import { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

// Components
// import { Input } from 'antd'
import Icon from '@/assets/icons'

// Styles
import styles from './style.module.scss'

// Variables
const cx = classnames.bind(styles)

// PropTypes
const propTypes = {
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  onKeyDown: PropTypes.func,
  autoFocus: PropTypes.bool,
  type: PropTypes.string,
  hasError: PropTypes.bool,
  isFieldFocus: PropTypes.bool,
  placeholder: PropTypes.string,
}

const defaultProps = {
  hasError: false,
}

function InputComponent(props) {
  const { className, autoFocus, type, hasError, isFieldFocus, wrapperClassName, placeholder = '', ...restProps } = props

  const isPasswordType = type === 'password'

  const [currentInputType, setInputType] = useState(type)

  const isPasswordHidden = currentInputType === 'password'

  const inputRef = useRef(null)

  useEffect(() => {
    if (autoFocus || isFieldFocus) inputRef.current?.focus()
  }, [autoFocus, isFieldFocus])

  const handleEyeClick = () => {
    if (isPasswordType) {
      if (isPasswordHidden) {
        setInputType('text')
      } else {
        setInputType('password')
      }
    }
  }

  return (
    <div className={cx('input-wrapper', wrapperClassName)}>
      <input
        ref={inputRef}
        className={cx('input-wrapper-input', className)}
        type={currentInputType}
        data-is-password={isPasswordType}
        data-has-error={hasError}
        placeholder={placeholder}
        {...restProps}
      />
      {isPasswordType && (
        <div className={cx('input-wrapper-icon')} onClick={handleEyeClick}>
          {isPasswordHidden && <Icon.EyeCloseImage className={cx('input-wrapper-icon-image')} />}
          {!isPasswordHidden && <Icon.EyeOpenImage className={cx('input-wrapper-icon-image')} />}
        </div>
      )}
    </div>
  )
}

InputComponent.propTypes = propTypes
InputComponent.defaultProps = defaultProps

export default InputComponent
