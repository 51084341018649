import Service, { getCallApiFunction } from '../service'
// import denormalizer from './denormalizer'
// import normalizer from './normalizer'

class FetchSearchHotKeyLiveApi extends Service {
  constructor() {
    super()
    this.name = 'FETCH_SEARCH_HOTKEY_LIVE'

    this.config = {
      url: `/v1/search/hot-keyword-live`,
      method: 'GET',
    }
  }
}

export const fetchSearchHotKeyLive = () => getCallApiFunction(new FetchSearchHotKeyLiveApi())
