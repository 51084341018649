interface Message {
  socket?: any
  socketRef?: any
  content: object
}

// emit socket message
export const handleMessageEmit = ({ socket, socketRef, content }: Message) => {
  if (socket) {
    socket?.emit('CHANNEL', JSON.stringify(content))
  } else {
    socketRef?.current?.emit('CHANNEL', JSON.stringify(content))
  }
}
