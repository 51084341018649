import * as Sentry from '@sentry/nextjs'
import { SeverityLevel } from '@sentry/types'

type EventParams = {
  message: string
  level: SeverityLevel
  user?: any
  extra?: any
  tags?: any
}

export const handleSentryEvent = ({ message, level = 'info', user, extra, tags }: EventParams) => {
  Sentry.captureEvent({
    message,
    level,
    user,
    extra,
    tags,
  })
}
