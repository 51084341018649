import { useEffect, useState, useRef } from 'react'
import { useRouter } from 'next/router'

// Libs
import handleRoutePushWithQuery from '@/methods/url/handleRoutePushWithQuery'
import useSearchHotKey from '@/hooks/useSearchHotKey'
import useSearchHistory from '@/hooks/useSearchHistory'

// Components

// Constants
import { ROUTE_PATH } from '@/constants/routePath'
import { SEARCH_TYPE } from '@/constants/search'

const useAutoCompleteAction = () => {
  const router = useRouter()
  const initialKeyword = typeof router?.query?.keyword === 'string' ? router.query.keyword : ''
  const [autoCompleteKeyword, setAutoCompleteKeyword] = useState(initialKeyword)
  const [autoCompleteIsOpen, setAutoCompleteIsOpen] = useState(false)
  const [autoCompleteSelectIndex, setAutoCompleteSelectIndex] = useState<number | null>(null)
  const { filterKeywordList = [] } = useSearchHotKey({ autoCompleteKeyword: autoCompleteKeyword })
  const { setHistoryLocalStorage } = useSearchHistory()
  const inputRef = useRef<any>(null)

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      // 在這裡處理 Enter 鍵按下事件
      const keyword = autoCompleteSelectIndex !== null ? filterKeywordList[autoCompleteSelectIndex].keyword : event.target.value
      handleRoutePushWithQuery({
        router,
        pathname: ROUTE_PATH.SEARCH,
        query: { keyword: keyword, type: SEARCH_TYPE.ALL },
      })
      setAutoCompleteIsOpen(false)
      setAutoCompleteKeyword(keyword)
      setHistoryLocalStorage(keyword)
    }
  }
  const handleKeyDown = (event: any) => {
    //上下鍵
    if (event.key === 'ArrowDown') {
      if (autoCompleteSelectIndex === null) {
        setAutoCompleteSelectIndex(0)
      } else {
        setAutoCompleteSelectIndex(autoCompleteSelectIndex + 1 === filterKeywordList.length ? autoCompleteSelectIndex : autoCompleteSelectIndex + 1)
      }
    }
    if (event.key === 'ArrowUp') {
      if (autoCompleteSelectIndex === null) {
        setAutoCompleteSelectIndex(0)
      } else {
        setAutoCompleteSelectIndex(autoCompleteSelectIndex === 0 ? 0 : autoCompleteSelectIndex - 1)
      }
    }
    //esc
    if (event.key === 'Escape') {
      setAutoCompleteIsOpen(false)
      setAutoCompleteSelectIndex(null)
    }
  }
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (inputRef.current && inputRef.current.contains(event.target)) {
        setAutoCompleteIsOpen(true)
      } else {
        setAutoCompleteIsOpen(false)
        setAutoCompleteSelectIndex(null)
      }
    }
    document.addEventListener('click', handleOutsideClick)

    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [])
  return {
    handleKeyPress,
    handleKeyDown,
    autoCompleteKeyword: autoCompleteKeyword as string,
    setAutoCompleteKeyword,
    autoCompleteIsOpen,
    setAutoCompleteIsOpen,
    autoCompleteSelectIndex,
    setAutoCompleteSelectIndex,
    inputRef,
  }
}
export default useAutoCompleteAction
