import Service, { getCallApiFunction } from '../service'
// import denormalizer from './denormalizer'
// import normalizer from './normalizer'

export type ParamsType = {
  shoppingcartId?: number
}

class FetchCartDataApi extends Service {
  constructor(params: ParamsType) {
    super()
    this.name = 'FETCH_CART_DATA'

    this.config = {
      url: `/v1/cart`,
      method: 'GET',
      params: {
        shoppingcartId: params.shoppingcartId,
      },
    }
  }
}

// export type CartDataType = {}

export const fetchCartData = (params: ParamsType) => getCallApiFunction(new FetchCartDataApi(params))
