import { isEmpty } from 'lodash'

// Libs
import handleLocationHrefDirect from '@/methods/url/handleLocationHrefDirect'
import getSearchParams from '@/methods/url/getSearchParams'
import getUrlWithNewSearch from '@/methods/url/getUrlWithNewSearch'
import { handleGtmCtaLogin } from '@/methods/handleGtmEvent'

// Constants
import { envConfigs } from '@/constants/config'
import { CLASS_NAME } from '@/constants/className'
import { ROUTE_PATH } from '@/constants/routePath'
import { STORAGE_DATA } from '@/constants/storageData'

export const handleLoginOrRegister = async ({
  isLogin = true,
  router,
  hasUserData = false,
  setUserRecordMutation,
  fixedPlayerData = {},
  isGoRedirect = false,
  gtmLocation = '',
}: {
  isLogin?: boolean
  router: any
  hasUserData?: boolean
  setUserRecordMutation: any
  fixedPlayerData: any
  isGoRedirect?: boolean
  gtmLocation?: string
  locale?: string
}) => {
  if (hasUserData) return

  if (gtmLocation) handleGtmCtaLogin({ location: gtmLocation }) // 一般 location 可以給空字串(後台系統可以看出哪些沒給)，但在 useAuthorization 不觸發 gtm 事件，所以沒給 location 就先擋掉

  const searchParams: any = getSearchParams()
  const { fromWhere, m, s, jobId } = searchParams

  const { asPath } = router

  const loginOrRegisterUrl = isLogin ? envConfigs?.logInUrl : envConfigs?.registerUrl

  const redirectUrl = `${window.location.origin}/${ROUTE_PATH.REDIRECT}?path=${asPath}`
  const redirectUrlWithSearch =
    getUrlWithNewSearch({
      url: redirectUrl,
      isLowerCase: false,
      searchParams: searchParams,
    }) || ''

  // 有些頁面登入回來要先導去 /redirect 再回原本頁面 // TODO: 例如直播間，因為目前直接回原本頁面可能 user-auth 還沒跑完，這時拿 profile 會有問題
  const returnUrl = isGoRedirect ? redirectUrlWithSearch : window.encodeURIComponent(window.location.href)

  if (!isEmpty(fixedPlayerData?.liveUrlInfo)) {
    window.sessionStorage.setItem(STORAGE_DATA.FIXED_PLAYER_CACHE_DATA, JSON.stringify(fixedPlayerData))
  }

  const result = await setUserRecordMutation.mutateAsync(
    {
      classname: CLASS_NAME.SHOP_REGISTER,
      fromWhere,
      m,
      s,
    },
    {
      onError: () => {
        const url = `${loginOrRegisterUrl}?returnUrl=${returnUrl}`

        handleLocationHrefDirect({ router, url })
      },
    },
  )

  const url = `${loginOrRegisterUrl}?jobId=${jobId || result}&returnUrl=${returnUrl}`

  handleLocationHrefDirect({ router, url })
}
