import { useEffect, useState } from 'react'
import { STORAGE_DATA } from '@/constants/storageData'

const useSearchHistory = () => {
  const isServer = typeof window === 'undefined'
  const [historyLocalStorage, setHistoryLocalStorageState] = useState<string[]>([])

  function getHistoryLocalStorage() {
    if (!isServer) {
      try {
        return JSON.parse(window.localStorage.getItem(STORAGE_DATA.SEARCH_HISTORY) || '[]')
      } catch (error) {
        return []
      }
    }
  }

  // 用 Effect + state 的原因是為了確保不會有 SSR 跟 CSR 資料不同造成的 hydration error
  useEffect(() => {
    const historyLocalStorage: string[] = getHistoryLocalStorage() || []
    setHistoryLocalStorageState(historyLocalStorage)
  }, [])

  const setHistoryLocalStorage = (history: string) => {
    if (history.trim() !== '') {
      window.localStorage.setItem(
        STORAGE_DATA.SEARCH_HISTORY,
        JSON.stringify([history, ...historyLocalStorage.filter((item) => item !== history)].slice(0, 8)),
      )
      setHistoryLocalStorageState([history, ...historyLocalStorage.filter((item) => item !== history)].slice(0, 8))
    }
  }
  return {
    setHistoryLocalStorage,
    historyLocalStorage,
  }
}

export default useSearchHistory
