import ReactDOM from 'react-dom'
// import classnames from 'classnames/bind'

// Libs

// Components

// Styles
// import styles from './style.module.scss'

// Variables
// const cx = classnames.bind(styles)

// PropTypes
type PropTypes = {
  children: any
  isFullScreen?: boolean
}

function PortalWithPlayer(props: PropTypes) {
  const { children, isFullScreen } = props

  const videoContainer: any = typeof window === 'undefined' ? null : document.getElementById('playerVideo')

  return (
    <>
      {isFullScreen && ReactDOM.createPortal(children, videoContainer)}
      {!isFullScreen && children}
    </>
  )
}

export default PortalWithPlayer
