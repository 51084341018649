import { NextRouter } from 'next/router'
import { execute } from '@tutorabc/webview-jsbridge'

// Libs
import handleRoutePushWithQuery from '@/methods/url/handleRoutePushWithQuery'

// constants
import { isClientSideShopApp } from '@/utils/navigator'
import { ROOM_LIVE_TYPE } from '@/constants/liveRoom'
import { ROUTE_PATH } from '@/constants/routePath'
import { BRIDGE_HANDLER } from '@/constants/bridgeHandler'

interface IGoToLiveRoom {
  router: NextRouter
  roomId: string
  liveType: number
  liveSourceType?: number
}

export const goToLiveRoom = ({ router, roomId, liveType = ROOM_LIVE_TYPE.DEFAULT, liveSourceType }: IGoToLiveRoom) => {
  const isLiveOrFuture = liveType === ROOM_LIVE_TYPE.CURRENT || liveType === ROOM_LIVE_TYPE.FUTURE
  if (isClientSideShopApp && isLiveOrFuture) {
    execute({
      handlerName: BRIDGE_HANDLER.OPEN_ROOM,
      data: {
        id: roomId,
        liveType,
        liveSourceType,
      },
    })
  } else {
    handleRoutePushWithQuery({ router, pathname: `${ROUTE_PATH.LIVE}/${roomId}` })
  }
}
