import { createSlice } from '@reduxjs/toolkit'

import { FACINGMODE_OPTIONS } from '@/constants/liveRoom'
import { isMobile2 } from '@/utils/navigator'

// interface MediaItem {
//   id: string
// }

interface DeviceType {
  id: string
  label: string
  kind: string
}

export interface MediaState {
  mediaData: {
    videoDevices: DeviceType[]
    audioDevices: DeviceType[]
    audioOutputDevices: any[]
    currentVideoInputIndex: number
    audioEnable: boolean
    videoEnable: boolean
    currentAudioInputId: string
    currentVideoInputId: string
  }
  hasAccess: boolean
  isMicEnabled: boolean
  isCameraEnabled: boolean
}

const initialState: MediaState = {
  mediaData: {
    videoDevices: [],
    audioDevices: [],
    audioOutputDevices: [],
    currentVideoInputIndex: 0,
    currentAudioInputId: '',
    currentVideoInputId: '',
    audioEnable: false,
    videoEnable: false,
  },
  hasAccess: false,
  isMicEnabled: true,
  isCameraEnabled: true,
}

export const mediaSlice = createSlice({
  name: 'media',
  initialState,
  reducers: {
    setMediaData: (state, { payload }) => {
      //TODO: 教室會把 videoId 或 audioId 存在 localStorage，不確定 shop 需不需要

      // audio
      let currentAudioInputId = ''

      let audioId = (payload.audioInputDeviceList.length && payload.audioInputDeviceList[0]).id
      payload.audioInputDeviceList.forEach((element: any) => {
        if (element.id === 'default') {
          audioId = element.id
        }
      })

      currentAudioInputId = audioId
      // use video facingMode for mobile devices
      if (isMobile2) {
        payload.videoDeviceList = FACINGMODE_OPTIONS.map((option) => ({ id: option }))
      }

      const currentVideoInputId = payload.videoDeviceList.length && payload.videoDeviceList[0].id

      const mediaData = {
        currentVideoInputIndex: state.mediaData.currentVideoInputIndex,
        currentAudioInputId,
        currentVideoInputId,
        audioDevices: payload.audioInputDeviceList,
        videoDevices: payload.videoDeviceList,
        audioOutputDevices: payload.audioOutputDeviceList,
        audioEnable: payload.audioInputDeviceList.length > 0,
        videoEnable: payload.videoDeviceList.length > 0,
      }

      console.log(`mediaData: ${JSON.stringify(mediaData)}`)

      state.mediaData = mediaData
    },
    setVideoInput: (state, { payload }) => {
      state.mediaData.currentVideoInputIndex = payload.currentVideoInputIndex
      state.mediaData.currentVideoInputId = state.mediaData.videoDevices[payload.currentVideoInputIndex].id
    },
    setHasAccess: (state, { payload }) => {
      state.hasAccess = payload.hasAccess
    },
    setIsMicEnabled: (state, { payload }) => {
      state.isMicEnabled = payload.isMicEnabled
    },
    setIsCameraEnabled: (state, { payload }) => {
      state.isCameraEnabled = payload.isCameraEnabled
    },
  },
})

export const { setMediaData, setHasAccess, setVideoInput, setIsMicEnabled, setIsCameraEnabled } = mediaSlice.actions

export default mediaSlice.reducer
