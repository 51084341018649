import { execute } from '@tutorabc/webview-jsbridge'

// Libs
import { handleMessageEmit } from '@/methods/message/handleMessageEmit'
import { isClientSideShopApp } from '@/utils/navigator'

// Constants
import { DEVICE_STATUS, WS_EVENT, WS_EVENT_ROOM, WS_EVENT_ROOM_STATES, WS_EVENT_SHOP_STATE } from '@/constants/socketEvent'
import { BRIDGE_HANDLER } from '@/constants/bridgeHandler'

type AddCartData = {
  socket: any
  item: { productId: number; name: string }
  userData: {
    nickName: string
    headshotUrl: string
    role: number
    userToken: string
  }
}

export const getRoomSocketUsers = ({ socket }: any) => {
  const infoContent = {
    sid: WS_EVENT_ROOM.sid,
    ssid: WS_EVENT_ROOM.state,
    body: [{ key: WS_EVENT_ROOM_STATES.participant, value: 0 }],
  }

  handleMessageEmit({ socket, content: infoContent })
}

export const handleCameraDevice = ({ socket, userToken, isEnabled }: { socket: any; userToken: string; isEnabled: boolean }) => {
  const cameraPacket = {
    sid: WS_EVENT_ROOM.sid,
    ssid: WS_EVENT_ROOM.userState,
    body: [
      { key: 'token', value: userToken },
      { key: 'camera', value: isEnabled ? DEVICE_STATUS.ON : DEVICE_STATUS.OFF },
    ],
  }

  handleMessageEmit({ socket, content: cameraPacket })
}

export const handleMicDevice = ({ socket, userToken, isEnabled }: { socket: any; userToken: string; isEnabled: boolean }) => {
  const micPacket = {
    sid: WS_EVENT_ROOM.sid,
    ssid: WS_EVENT_ROOM.userState,
    body: [
      { key: 'token', value: userToken },
      { key: 'mic', value: isEnabled ? DEVICE_STATUS.ON : DEVICE_STATUS.OFF },
    ],
  }

  handleMessageEmit({ socket, content: micPacket })
}

export const handleAddCartMessage = ({ socket, item, userData }: AddCartData) => {
  const addCartPacket = {
    sid: WS_EVENT_SHOP_STATE.sid,
    ssid: WS_EVENT_SHOP_STATE.state,
    body: {
      type: WS_EVENT.ADD2CART,
      content: {
        prod_id: item.productId,
        prod_name: item.name,
      },
      from: {
        sender: userData.userToken,
        name: userData.nickName,
        head_url: userData.headshotUrl,
        role: userData.role,
      },
    },
  }

  if (isClientSideShopApp) {
    execute({
      handlerName: BRIDGE_HANDLER.SEND_SOCKET,
      data: {
        event: addCartPacket,
      },
    })
  }

  handleMessageEmit({ socket, content: addCartPacket })
}

export const handleMessageLimit = ({ socket, userToken, canChat }: { socket: any; userToken: string; canChat: number }) => {
  const limitMessagePacket = {
    sid: WS_EVENT_ROOM.sid,
    ssid: WS_EVENT_ROOM.userState,
    body: [
      { key: 'token', value: userToken },
      { key: 'canChat', value: canChat },
    ],
  }

  handleMessageEmit({ socket, content: limitMessagePacket })
}

export const getLimitMessageUsers = ({ socket }: any) => {
  const limitMessageContent = {
    sid: WS_EVENT_ROOM.sid,
    ssid: WS_EVENT_ROOM.state,
    body: [{ key: WS_EVENT_ROOM_STATES.silentUsers }],
  }

  handleMessageEmit({ socket, content: limitMessageContent })
}
