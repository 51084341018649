type DataType = {
  classname?: string
  fromWhere?: string
  m?: string
  s?: string
  note?: string
}

const denormalizer = ({ classname, fromWhere, m, s, note }: DataType) => {
  return {
    classname,
    fromwhere: fromWhere,
    mgmCode: m,
    sgmCode: s,
    note,
  }
}

export default denormalizer
