import Service, { getCallApiFunction } from '../service'
import denormalizer from './denormalizer'
// import normalizer from './normalizer'
import { envConfigs } from '@/constants/config'

type DataType = {
  classname: string
  fromWhere?: string
  m?: string
  s?: string
  note?: string
}

class SetUserRecordApi extends Service {
  constructor(data: DataType) {
    super()
    this.name = 'SET_USER_RECORD'

    this.config = {
      url: `/v1/configuration/jobId`,
      method: 'POST',
      data,
      timeout: 5000,
      baseURL: envConfigs.dittoApiUrl,
    }

    this.denormalizer = denormalizer
  }
}

export const setUserRecord = (data: DataType) => getCallApiFunction(new SetUserRecordApi(data))
