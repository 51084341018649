export const ORDER_TYPE = {
  // api回的是number
  ALL_LIST: '',
  CHECKING: '10',
  PREPARED: '20',
  DELIVERING: '30,35,40',
  ARRIVED: '50',
  RETURNED: '100', // 目前只有退換貨 api 沒定義 status, 先定 100
  CANCELED: '0', // 已取消的訂單
  INVALID: '1', // 無效的訂單
}
