import { createSlice } from '@reduxjs/toolkit'

export interface IInitialState {
  data: any[]
}

const initialState: IInitialState = {
  data: [],
}

export const mallSlice = createSlice({
  name: 'mall',
  initialState,
  reducers: {
    setCategoryMenuData: (state, { payload }) => {
      const { data } = payload

      state.data = data
    },
  },
})

export const { setCategoryMenuData } = mallSlice.actions

export default mallSlice.reducer
