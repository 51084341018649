import Service, { getCallApiFunction } from '../service'
// import denormalizer from './denormalizer'
// import normalizer from './normalizer'

class FetchSearchHotKeyApi extends Service {
  constructor() {
    super()
    this.name = 'FETCH_SEARCH_HOTKEY'

    this.config = {
      url: `/v1/search/hot-keyword`,
      method: 'GET',
    }
  }
}

export const fetchSearchHotKey = () => getCallApiFunction(new FetchSearchHotKeyApi())
