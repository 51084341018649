import Service, { getCallApiFunction } from '../service'
import denormalizer from './denormalizer'
// import normalizer from './normalizer'

export type ParamsType = {
  token?: string
  refreshToken?: string
}

class FetchUserAuthApi extends Service {
  constructor(params: ParamsType) {
    super()
    this.name = 'FETCH_USER_AUTH'

    this.config = {
      url: `/v1/user-auth`,
      method: 'GET',
      params,
    }

    this.denormalizer = denormalizer
  }
}

export const fetchUserAuth = (params: ParamsType) => getCallApiFunction(new FetchUserAuthApi(params))
