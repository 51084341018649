import { ParamsType } from './index'

const denormalizer = ({ token, refreshToken }: ParamsType) => {
  return {
    access: token,
    refresh: refreshToken,
  }
}

export default denormalizer
