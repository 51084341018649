import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { isEmpty } from 'lodash'
import { useRouter } from 'next/router'

// Libs
import { fetchRefreshToken } from '@/api/fetchRefreshToken'
import handleLogout from '@/methods/handleLogout'

// Constants
import { QUERY_KEY } from '@/constants/queryKey'
import { STORAGE_DATA } from '@/constants/storageData'

export const useRefreshToken = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [needRefreshToken, setNeedRefreshToken] = useState(false)
  const tokenFromLocalStorage = (typeof window !== 'undefined' && JSON.parse(<string>window.localStorage.getItem(STORAGE_DATA.TOKEN))) || {}
  const isTimeout = typeof window !== 'undefined' && JSON.parse(<string>window.localStorage.getItem(STORAGE_DATA.IS_TIMEOUT))

  const router = useRouter()
  const { query } = router

  const { data = {}, isFetched } = <any>(
    useQuery([QUERY_KEY.FETCH_REFRESH_TOKEN], () => fetchRefreshToken({ refreshToken: tokenFromLocalStorage?.refreshToken || query?.refreshToken }), {
      enabled: Boolean(isTimeout) && router.isReady && (!!tokenFromLocalStorage?.refreshToken || !!query?.refreshToken),
      onSuccess: () => window.localStorage.removeItem(STORAGE_DATA.IS_TIMEOUT),
      onError: () => handleLogout({ router }),
    })
  )

  const handleDetectionRefresh = () => {
    const isTimeout = typeof window !== 'undefined' && JSON.parse(<string>window.localStorage.getItem(STORAGE_DATA.IS_TIMEOUT))

    if (isTimeout) {
      setNeedRefreshToken(true)
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('storage', handleDetectionRefresh)
    }

    return () => {
      window.removeEventListener('storage', handleDetectionRefresh)
    }
  }, [])

  useEffect(() => {
    if (isFetched && !isEmpty(data)) {
      const { authToken, refreshToken } = data

      const tokenData = { token: authToken, refreshToken }

      window.localStorage.setItem(STORAGE_DATA.TOKEN, JSON.stringify(tokenData))
      setNeedRefreshToken(false)
    }
  }, [isFetched, data])
}
