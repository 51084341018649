import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// Libs
import { fetchCartId } from '@/api/fetchCartId'
import { fetchCartData } from '@/api/fetchCartData'
import { updateCartData } from '@/api/updateCartData'

// Constant
import { STORAGE_DATA } from '@/constants/storageData'

// Thunk
export const fetchShoppingCartIdThunk = createAsyncThunk('shoppingCart/fetchCartId', async () => {
  const response = await fetchCartId({})

  return response
})

export const fetchShoppingCartDataThunk = createAsyncThunk('shoppingCart/fetchCartData', async (data, thunkAPI) => {
  const state: any = thunkAPI.getState()
  const { shoppingCart } = state

  let shoppingcartId = shoppingCart.shoppingcartId

  if (!shoppingcartId) {
    const result = await thunkAPI.dispatch(fetchShoppingCartIdThunk())

    shoppingcartId = result.payload.shoppingcartId

    window.localStorage.setItem(STORAGE_DATA.SHOPPINGCART_ID, shoppingcartId)
  }

  let response: any = null

  try {
    response = await fetchCartData({ shoppingcartId })
  } catch (error: any) {
    // 對應不到指定的購物車 ID，會重新取得另一台購物車 ID 給用戶
    // 只幫忙重取一次，因為考量用 recursion，如果 API 壞掉，會無窮迴圈
    if (error.errorCode === -5002) {
      const result = await thunkAPI.dispatch(fetchShoppingCartIdThunk())

      shoppingcartId = result.payload.shoppingcartId
      window.localStorage.setItem(STORAGE_DATA.SHOPPINGCART_ID, shoppingcartId)
      response = await fetchCartData({ shoppingcartId })
    }
  }

  return response
})

export const updateCartDataThunk = createAsyncThunk('shoppingCart/updateCartData', async (data: UpdateCartProps, thunkAPI) => {
  const { action, quantity, roomId, skuId, skuList } = data

  const state: any = thunkAPI.getState()
  const { shoppingCart } = state

  let shoppingcartId = shoppingCart.shoppingcartId

  if (!shoppingcartId) {
    const result = await thunkAPI.dispatch(fetchShoppingCartIdThunk())

    shoppingcartId = result.payload.shoppingcartId

    window.localStorage.setItem(STORAGE_DATA.SHOPPINGCART_ID, shoppingcartId)
  }

  const response = await updateCartData({ action, quantity, roomId, skuId, shoppingcartId, skuList })

  return response
})

interface UpdateCartProps {
  action: number
  quantity?: number
  roomId: string
  skuId?: number
  skuList?: {
    quantity: number
    skuId: number
    room_id?: string
    referralFrom?: string
    referralId?: string
  }[]
}

export interface ISelectorState {
  [key: string]: {
    selected: boolean
  }
}

interface ISkuQuantity {
  [key: string]: {
    quantity: number
  }
}

export interface IFastCheckoutItem {
  merchantId: string
  skuId: string
  quantity: number
}

export interface IInitialState {
  selectorStatus: ISelectorState
  skuQuantity: ISkuQuantity
  deliveryMethodCVSInfo: any
  deliveryMethodHomeInfo: any
  shoppingcartId: string
  shoppingCartData: {
    invalidSkuList: any[]
    shoppingcartId: string
    validSkuList: any[]
  }
  cartAmount: number
  fastCheckoutItem: IFastCheckoutItem | null
  isCartDataSuccess: boolean
  isCartDataUpdating: boolean
}

const isBrowser = typeof window !== 'undefined'

const initialState: IInitialState = {
  selectorStatus: {},
  skuQuantity: {},
  deliveryMethodCVSInfo: {},
  deliveryMethodHomeInfo: {},
  shoppingcartId: isBrowser ? window.localStorage.getItem(STORAGE_DATA.SHOPPINGCART_ID) || '' : '',
  shoppingCartData: {
    invalidSkuList: [],
    shoppingcartId: '',
    validSkuList: [],
  },
  cartAmount: 0,
  fastCheckoutItem: null,
  isCartDataSuccess: false,
  isCartDataUpdating: false,
}

const getValidSkuList = ({ shoppingCartData }: any) => {
  // eslint-disable-next-line no-extra-boolean-cast
  const validSkuList = !!shoppingCartData?.validSkuList ? shoppingCartData?.validSkuList : []
  let result: any[] = []

  if (validSkuList?.length > 0) {
    validSkuList.forEach((item: any) => {
      result = result.concat(item.skuList)
    })
  }

  return result
}

export const shoppingCartSlice = createSlice({
  name: 'shoppingCart',
  initialState,
  reducers: {
    updateSelectorStatus: (state, { payload }) => {
      state.selectorStatus = payload
    },
    updateSkuQuantity: (state, { payload }) => {
      state.skuQuantity = payload
    },
    updateDeliveryMethodCVSInfo: (state, { payload }) => {
      state.deliveryMethodCVSInfo = payload
    },
    updateDeliveryMethodHomeInfo: (state, { payload }) => {
      state.deliveryMethodHomeInfo = payload
    },
    setFastCheckoutItem: (state, { payload }) => {
      state.fastCheckoutItem = payload
    },
    resetFastCheckoutItem: (state) => {
      state.fastCheckoutItem = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchShoppingCartIdThunk.fulfilled, (state, action) => {
      state.shoppingcartId = action.payload.shoppingcartId
    })
    builder.addCase(fetchShoppingCartDataThunk.pending, (state) => {
      state.isCartDataSuccess = false
    })
    builder.addCase(fetchShoppingCartDataThunk.fulfilled, (state, action) => {
      state.shoppingCartData = action.payload
      state.cartAmount = getValidSkuList({ shoppingCartData: action.payload }).length
      state.isCartDataSuccess = true
    })
    builder.addCase(updateCartDataThunk.pending, (state, action) => {
      const shoppingCartData = action.payload || state.shoppingCartData

      state.shoppingCartData = shoppingCartData
      state.cartAmount = getValidSkuList({ shoppingCartData }).length
      state.isCartDataUpdating = true
    })
    builder.addCase(updateCartDataThunk.fulfilled, (state, action) => {
      const shoppingCartData = action.payload || state.shoppingCartData

      state.shoppingCartData = shoppingCartData
      state.cartAmount = getValidSkuList({ shoppingCartData }).length
      state.isCartDataUpdating = false
    })
    builder.addCase(updateCartDataThunk.rejected, (state) => {
      state.isCartDataUpdating = false
    })
  },
})

export const {
  updateSelectorStatus,
  updateSkuQuantity,
  updateDeliveryMethodCVSInfo,
  updateDeliveryMethodHomeInfo,
  setFastCheckoutItem,
  resetFastCheckoutItem,
} = shoppingCartSlice.actions

export default shoppingCartSlice.reducer
