import Service, { getCallApiFunction } from '../service'
// import denormalizer from './denormalizer'
// import normalizer from './normalizer'

export type ParamsType = {
  action: number
  quantity?: number
  roomId: string
  shoppingcartId: string
  skuId?: number
  skuList?: {
    quantity: number
    skuId: number
    room_id?: string
  }[]
}

class UpdateCartIdApi extends Service {
  constructor(params: ParamsType) {
    super()
    this.name = 'UPDATE_CART_DATA'

    this.config = {
      url: `/v1/cart`,
      method: 'PATCH',
      data: { ...params },
    }
  }
}

export const updateCartData = (params: ParamsType) => getCallApiFunction(new UpdateCartIdApi(params))
