let ua = typeof window !== 'undefined' && window.navigator.userAgent.toLowerCase()
const isMobile = ua && !!ua.match(/AppleWebKit.*Mobile.*/i)
const isAndroid = ua && (!!ua.match(/android/i) || !!ua.match(/adr/i))
const isIOS = ua && !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/i)
const isIphone = ua && !!ua.match(/iphone/i)
const isWechat = ua && !!ua.match(/MicroMessenger/i)
const isPad = ua && !!ua.match(/ipad/i)
const isMac = ua && !!ua.match(/macintosh/i)
const isWindows = ua && !!ua.match(/windows/i)
const isX64 = ua && (ua.indexOf('x64') > -1 || ua.indexOf('wow64') > -1)
const isMobile2 = isAndroid || isIphone || isPad
const isMacOrIOS = isIOS || isMac

const isClientSideShopApp = ua && !!ua.match(/tutorshop/i)

export { isMobile, isAndroid, isIOS, isIphone, isWechat, isPad, isMac, isWindows, isX64, isMobile2, isMacOrIOS, isClientSideShopApp }
