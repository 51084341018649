import { useAppSelector } from '@/redux/hooks'

// Constants
import { LAYOUT } from '@/constants/layout'

const useMedia = () => {
  const deviceType = useAppSelector((state) => state.common.currentLayout)
  const isDesktopM = useAppSelector((state) => state.common.isDesktopM)
  const isDesktopMiddle = useAppSelector((state) => state.common.isDesktopMiddle)
  const isPortrait = useAppSelector((state) => state.common.isPortrait)

  return {
    isMobile: deviceType === LAYOUT.MOBILE,
    isTablet: deviceType === LAYOUT.TABLET,
    isDesktop: deviceType === LAYOUT.DESKTOP,
    isDesktopM,
    isDesktopMiddle,
    isPortrait,
  }
}

export default useMedia
