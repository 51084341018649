/* eslint-disable no-prototype-builtins */
import TagManager from 'react-gtm-module'
import {
  GTM_EVENT,
  GtmClickViewCouponText,
  GtmCtaRemoveFromCartMethod,
  GtmClickLiveRoomPosition,
  GtmShareMethod,
  GtmSocialChannelChannel,
  GtmClickChangeVarientText,
  GtmClickLiveRoomLiveStatus,
  GtmShareContentType,
  GtmClickLiveRoomSetupClickText,
  GtmClickLiveRoomSetupPlayStatus,
} from '@/constants/gtmEvent'
import { handleCamelToSnake } from './handleCamelToSnake'
import { GtmNavigationParams, GtmCtaAddToCartText, GtmCategoryClassType, GtmUpdateCartAction, GtmClickRoomListTabType } from '@/constants/gtmEvent'
import { i18n } from 'next-i18next'
import { ProductInfoType } from '@/api/fetchProductData'

type GtmType = {
  event: string
  eventData?: any
  isEcommerce?: boolean
}

interface IProductItems {
  itemName: string
  itemId: string
  itemBrand: string
  itemCategory: string
  price: string
  quantity: number
  itemVarient: string[]
  discount?: number
}

const convertDataKeyToSnakeCase = (eventData: any) => {
  // 遍歷所有的 key 並轉成 snake case
  const convertedEventData: { [key: string]: unknown } = {}
  Object.entries(eventData).forEach(([key, value]) => {
    if (value instanceof Array) {
      convertedEventData[handleCamelToSnake({ string: key })] = value.map((item) => {
        if (item instanceof Object) {
          return convertDataKeyToSnakeCase(item)
        } else {
          return item
        }
      })
      return
    }
    if (value instanceof Object) {
      convertedEventData[handleCamelToSnake({ string: key })] = convertDataKeyToSnakeCase(value)
      return
    }
    convertedEventData[handleCamelToSnake({ string: key })] = value
  })

  return convertedEventData
}

const handleGtmEvent = async ({ event, eventData, isEcommerce = false }: GtmType) => {
  const convertedEventData = isEcommerce ? convertDataKeyToSnakeCase({ ecommerce: eventData }) : convertDataKeyToSnakeCase({ ...eventData })
  if (isEcommerce) {
    // ref: https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=10771576314382935158-AP&client_type=gtm&hl=zh-tw#view_item_list
    // 電商事件需要先清空 dataLayer 再 push
    TagManager.dataLayer({ dataLayer: { ecommerce: null } })
  }
  TagManager.dataLayer({
    dataLayer: {
      event,
      ...convertedEventData,
    },
  })
}

export default handleGtmEvent

export const handleGtmCtaLogin = ({ location = '' }) => {
  handleGtmEvent({
    event: GTM_EVENT.LOGIN,
    eventData: {
      location,
    },
  })
}

export const handleGtmClickNavigation = ({ clickText }: { clickText: GtmNavigationParams }) => {
  handleGtmEvent({
    event: GTM_EVENT.HEADER.NAVIGATION,
    eventData: {
      clickText: clickText,
    },
  })
}

export const handleGtmClickAds = ({ clickText }: { clickText: string }) => {
  handleGtmEvent({
    event: GTM_EVENT.CLICK_ADS,
    eventData: {
      clickText: clickText,
    },
  })
}

export const handleGtmClickCategory = ({
  clickText,
  classType,
  location,
}: {
  clickText: string
  classType: GtmCategoryClassType
  location: string
}) => {
  handleGtmEvent({
    event: GTM_EVENT.CATEGORY,
    eventData: {
      clickText,
      classType,
      location,
    },
  })
}

export const handleGtmViewPromotion = ({ creativeName, creativeSlot }: { creativeName: string; creativeSlot: string }) => {
  handleGtmEvent({
    event: GTM_EVENT.VIEW_PROMOTION,
    eventData: {
      creativeName,
      creativeSlot,
    },
    isEcommerce: true,
  })
}

export const handleGtmSelectPromotion = ({ creativeName, creativeSlot }: { creativeName: string; creativeSlot: string }) => {
  handleGtmEvent({
    event: GTM_EVENT.SELECT_PROMOTION,
    eventData: {
      creativeName,
      creativeSlot,
    },
    isEcommerce: true,
  })
}

export const handleGtmViewItemList = ({
  itemListName,
  itemListId,
  productInfoList,
}: {
  itemListName: string
  itemListId?: string
  productInfoList: Array<ProductInfoType>
}) => {
  const items = productInfoList.map((productInfo) => ({
    itemName: productInfo.productName,
    itemId: productInfo.productId,
    itemBrand: productInfo.merchantName,
    itemCategory: productInfo.categoryName,
    itemListName: `${GtmCategoryClassType.CATEGORY}_${productInfo.categoryName}`,
    itemListId: `${GtmCategoryClassType.CATEGORY}_id_${productInfo.categoryId}`,
  }))
  handleGtmEvent({
    event: GTM_EVENT.VIEW_ITEM_LIST,
    eventData: {
      itemListName,
      itemListId,
      items,
    },
    isEcommerce: true,
  })
}

export const handleGtmViewItemListCustomized = ({ itemListName, itemListId, items }: { itemListName: string; itemListId?: string; items: any }) => {
  handleGtmEvent({
    event: GTM_EVENT.VIEW_ITEM_LIST,
    eventData: {
      itemListName,
      itemListId,
      items,
    },
    isEcommerce: true,
  })
}

export const handleGtmCtaAddToCart = ({ clickTextI18Key, location }: { clickTextI18Key: GtmCtaAddToCartText; location: string }) => {
  handleGtmEvent({
    event: GTM_EVENT.CTA_ADD_TO_CART,
    eventData: {
      clickText: i18n?.t(clickTextI18Key, { lng: 'zh-tw' }),
      location,
    },
  })
}

export type THandleGtmUpdateCart = {
  event: GtmUpdateCartAction
  items: Array<TUpdateCartItem>
  currency: string
}

type TUpdateCartItem = {
  itemName: string
  itemId: number
  itemBrand: string
  itemCategory: string
  itemVarient: string
  itemListName: string
  itemListId: number
  price: string
  quantity: number
}

export const handleGtmUpdateCart = (props: THandleGtmUpdateCart) => {
  const { event } = props
  handleGtmEvent({
    event: event,
    eventData: {
      ...props,
    },
    isEcommerce: true,
  })
}

type THandleGtmClickMerchant = {
  merchantDetail: {
    merchantName: string
    merchantId: string
  }
  location: string
}

export const handleGtmClickMerchant = ({ merchantDetail, location }: THandleGtmClickMerchant) => {
  handleGtmEvent({
    event: GTM_EVENT.CLICK_MERCHANT,
    eventData: {
      merchantDetail,
      location,
    },
  })
}

export const handleGtmCtaRemoveFromCart = ({ removalMethod }: { removalMethod: GtmCtaRemoveFromCartMethod }) => {
  handleGtmEvent({
    event: GTM_EVENT.CTA_REMOVE_FROM_CART,
    eventData: {
      removalMethod,
    },
  })
}

export const handleGtmClickViewCoupon = ({ clickTextI18Key, location }: { clickTextI18Key: GtmClickViewCouponText; location: string }) => {
  handleGtmEvent({
    event: GTM_EVENT.CLICK_VIEW_COUPON,
    eventData: {
      clickText: i18n?.t(clickTextI18Key, { lng: 'zh-tw' }),
      location,
    },
  })
}

export const handleGtmClickRoomListType = ({ tabType, location }: { tabType: GtmClickRoomListTabType; location: string }) => {
  handleGtmEvent({
    event: GTM_EVENT.CLICK_ROOM_LIST_TYPE,
    eventData: {
      tabType,
      location,
    },
  })
}
export const handleGtmBookCalendarLive = ({ location, roomId }: { location: string; roomId: string }) => {
  handleGtmEvent({
    event: GTM_EVENT.BOOK_CALENDAR_LIVE,
    eventData: {
      location,
      roomId,
    },
  })
}

export const handleGtmClickLiveRoom = ({
  liveStatus,
  position,
  roomId,
}: {
  liveStatus: GtmClickLiveRoomLiveStatus
  position: GtmClickLiveRoomPosition
  roomId: string
}) => {
  handleGtmEvent({
    event: GTM_EVENT.CLICK_LIVE_ROOM,
    eventData: {
      liveStatus,
      position,
      roomId,
    },
  })
}

export const handleGtmClickHost = ({ hostId, location }: { hostId: string; location: string }) => {
  handleGtmEvent({
    event: GTM_EVENT.CLICK_HOST,
    eventData: {
      hostId,
      location,
    },
  })
}

export const handleGtmShare = ({
  contentType,
  contentId,
  method,
}: {
  contentType: GtmShareContentType
  method: GtmShareMethod
  contentId: string
}) => {
  handleGtmEvent({
    event: GTM_EVENT.SHARE,
    eventData: {
      content: {
        contentType,
        contentId,
      },
      method,
    },
  })
}

export const handleGtmSocialChannel = ({ socialChannel, location }: { socialChannel: GtmSocialChannelChannel; location: string }) => {
  handleGtmEvent({
    event: GTM_EVENT.SOCIAL_CHANNEL,
    eventData: {
      socialChannel,
      location,
    },
  })
}

export const handleGtmClickCustomService = ({ location }: { location: string }) => {
  handleGtmEvent({
    event: GTM_EVENT.CLICK_CUSTOM_SERVICE,
    eventData: {
      location,
    },
  })
}

export const handleGtmViewCart = ({ items, currency }: { items: Array<TUpdateCartItem>; currency: string }) => {
  handleGtmEvent({
    event: GTM_EVENT.VIEW_CART,
    eventData: {
      items,
      currency,
    },
    isEcommerce: true,
  })
}

export const handleGtmClickChangeVarient = ({ clickText }: { clickText: string & GtmClickChangeVarientText }) => {
  handleGtmEvent({
    event: GTM_EVENT.CLICK_CHANGE_VARIENT,
    eventData: {
      clickText,
    },
  })
}

export const handleGtmLiveInteraction = ({ interactionType, hostId, roomId }: { interactionType: string; hostId: string; roomId: string }) => {
  handleGtmEvent({
    event: GTM_EVENT.LIVE_INTERACTION,
    eventData: {
      interactionType,
      hostId,
      roomId,
    },
  })
}

export const handleGtmClickLiveRoomSetup = (props: {
  isMuted?: boolean
  switchPip?: boolean
  playStatus?: GtmClickLiveRoomSetupPlayStatus
  clickText?: GtmClickLiveRoomSetupClickText
}) => {
  handleGtmEvent({
    event: GTM_EVENT.CLICK_LIVE_ROOM_SETUP,
    eventData: { ...props },
  })
}

export const handleGtmSelectItem = ({
  productInfoList,
  itemListName,
  itemListId = '',
}: {
  itemListName: string
  itemListId?: string
  productInfoList: Array<ProductInfoType>
}) => {
  const items = productInfoList.map((productInfo) => ({
    itemName: productInfo.productName,
    itemId: productInfo.productId,
    itemBrand: productInfo.merchantName,
    itemCategory: productInfo.categoryName,
    itemListName: `${GtmCategoryClassType.CATEGORY}_${productInfo.categoryName}`,
    itemListId: `${GtmCategoryClassType.CATEGORY}_id_${productInfo.categoryId}`,
    price: productInfo?.price ? productInfo.price : undefined,
  }))

  handleGtmEvent({
    event: GTM_EVENT.SELECT_ITEM,
    eventData: {
      itemListName,
      itemListId,
      items,
    },
    isEcommerce: true,
  })
}

export const handleGtmSelectItemForCalendar = ({
  productInfoList,
  itemListName,
  itemListId = '',
}: {
  itemListName: string
  itemListId?: string
  productInfoList: Array<ProductInfoType>
}) => {
  const items = productInfoList.map((productInfo) => ({
    itemName: productInfo.productName,
    itemId: productInfo.productId,
    itemBrand: productInfo.merchantName,
    itemCategory: productInfo.categoryName,
    itemListName,
    itemListId,
    price: productInfo?.price ? productInfo.price : undefined,
  }))

  handleGtmEvent({
    event: GTM_EVENT.SELECT_ITEM,
    eventData: {
      itemListName,
      itemListId,
      items,
    },
    isEcommerce: true,
  })
}

export const handleGtmViewItem = ({ productInfo }: { productInfo: ProductInfoType }) => {
  const items = [
    {
      itemName: productInfo.productName,
      itemId: productInfo.productId,
      itemBrand: productInfo.merchantName,
      itemCategory: productInfo.categoryName,
      itemListName: `${GtmCategoryClassType.CATEGORY}_${productInfo.categoryName}`,
      itemListId: `${GtmCategoryClassType.CATEGORY}_id_${productInfo.categoryId}`,
    },
  ]
  handleGtmEvent({
    event: GTM_EVENT.VIEW_ITEM,
    eventData: {
      items,
      currency: productInfo.currency,
    },
    isEcommerce: true,
  })
}

export const handleGtmAddBillingInfo = ({ addInfo }: { addInfo: string }) => {
  handleGtmEvent({
    event: GTM_EVENT.ADD_BILLING_INFO,
    eventData: {
      addInfo,
    },
  })
}

export const handleCtaGoAndPay = ({ location, clickText, paymentMethod }: { location: string; clickText: string; paymentMethod: string }) => {
  handleGtmEvent({
    event: GTM_EVENT.CTA_GOANPAY,
    eventData: {
      location,
      clickText,
      paymentMethod,
    },
  })
}

export const handleGtmClickCheckOrder = ({ clickText, location }: { clickText: string; location: string }) => {
  handleGtmEvent({
    event: GTM_EVENT.CLICK_CHECK_ORDER,
    eventData: {
      clickText,
      location,
    },
  })
}

export const handleGtmClickLiveCalendar = ({ clickText, location }: { clickText: string; location: string }) => {
  handleGtmEvent({
    event: GTM_EVENT.CLICK_LIVE_CALENDAR,
    eventData: {
      clickText,
      location,
    },
  })
}

export const handleGtmClickFilter = ({ clickCategory }: { clickCategory: string }) => {
  handleGtmEvent({
    event: GTM_EVENT.CLICK_FILTER,
    eventData: {
      clickDate: 'click_date',
      clickCategory,
    },
  })
}

export const handleGtmBeginCheckout = ({ currency, coupon, items }: { currency: string; coupon: string; items: IProductItems }) => {
  handleGtmEvent({
    event: GTM_EVENT.BEGIN_CHECKOUT,
    eventData: {
      currency,
      coupon,
      items,
    },
  })
}

export const handleGtmPurchase = ({
  coupon,
  transactionId,
  currency,
  value,
  shipping,
  items,
}: {
  coupon: string
  transactionId: string
  currency: string
  value: number
  shipping: number
  items: IProductItems
}) => {
  handleGtmEvent({
    event: GTM_EVENT.PURCHASE,
    eventData: {
      coupon,
      transactionId,
      currency,
      value,
      shipping,
      items,
    },
    isEcommerce: true,
  })
}
