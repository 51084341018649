import Service, { getCallApiFunction } from '../service'
// import denormalizer from './denormalizer'
// import normalizer from './normalizer'

export type ParamsType = {
  memberId?: string
}

class FetchCartIdApi extends Service {
  constructor(params: ParamsType) {
    super()
    this.name = 'FETCH_CART_ID'

    this.config = {
      url: `/v1/cart/id`,
      method: 'GET',
      params: {
        memberId: params.memberId,
      },
    }
  }
}

export type CartIdType = {
  memberId: string
  shoppingcartId: string
}

export const fetchCartId = (params: ParamsType) => getCallApiFunction(new FetchCartIdApi(params))
