import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { setPreviousPage } from '@/redux/reducers/common'
const usePreviousRoute = () => {
  const router = useRouter()
  const dispatch = useAppDispatch()
  const { previousPage } = useAppSelector((state) => state.common)

  const handleRouteChange = (url: string) => {
    dispatch(setPreviousPage({ currentPage: url }))
  }
  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])

  return previousPage
}
export default usePreviousRoute
