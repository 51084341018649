import { toast } from 'react-toastify'

interface ErrorData {
  errorCode: number
  errorData: any
  message: any
  name: string
  status: number
}

interface ToastData {
  error: ErrorData
  closeButton?: boolean
  autoClose?: number
}

interface ToastConfig {
  toastId: string
  theme?: any
  autoClose: number
}

const handleErrorByToast = ({ error, autoClose = 6000 }: ToastData) => {
  const toastConfig: ToastConfig = { toastId: 'api_error', autoClose }

  if (error.errorCode > 0) {
    toast.error(`${error?.message}`, { ...toastConfig })
  } else if (!error.errorCode && !error?.status) {
    toast.error(`[${error?.name}] 網路不穩定 請重試一次`, { ...toastConfig })
  } else {
    toast.error(`[${error?.name}]  code: ${error?.errorCode}, message: ${error?.message}`, { ...toastConfig })
  }
}

export default handleErrorByToast
