import { useEffect } from 'react'
import { useQuery } from 'react-query'

// Libs
import { fetchShoppingCartDataThunk } from '@/redux/reducers/shoppingCart'
import { useAppSelector, useAppDispatch } from '@/redux/hooks'

// Constants
import { QUERY_KEY } from '@/constants/queryKey'

const useCart = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.dispatchEvent(new Event('storage'))
    }
  }, [])

  const memberData = useAppSelector((state) => state.member?.memberData)

  const { memberInfoId } = memberData

  const isLogin = !!memberInfoId

  useQuery([QUERY_KEY.FETCH_CART_DATA, { isLogin }], () => dispatch(fetchShoppingCartDataThunk()), {})
}

export default useCart
