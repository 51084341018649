import { Dispatch } from 'react'

import { useRouter } from 'next/router'

// Libs
import handleRoutePushWithQuery from '@/methods/url/handleRoutePushWithQuery'
import { HeaderActionType } from '@/components/layouts/BasicLayout/components/Header/reducer/headerReducer'

// Components
import MenuItem from '../../shared/MenuItem'

// Constants
import { LANGUAGE_LIST } from '@/constants/defaultLocale'

// Styles

// Variables

type LanguaListType = {
  headerDispatch: Dispatch<any>
}

function LanguageList(props: LanguaListType) {
  const { headerDispatch } = props
  const router = useRouter()

  const { pathname, query } = router

  return (
    <>
      {LANGUAGE_LIST.map((item, index) => (
        <MenuItem
          key={index}
          text={item.label}
          handleClick={() => {
            document.documentElement.lang = item.value
            handleRoutePushWithQuery({ locale: item.value, router, pathname, query, shallow: false })
            headerDispatch({ type: HeaderActionType.HANDLE_LANGUAGE_LIST_OPEN })
          }}
        />
      ))}
    </>
  )
}

export default LanguageList
