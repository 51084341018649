// Libs
import { handleMessageEmit } from '@/methods/message/handleMessageEmit'

// Constants
import { HAND_UP_STATUS, WS_EVENT_LIVE_SHOW } from '@/constants/socketEvent'

export interface RoomHandUpParams {
  socket: any
  dispatch?: any
  isHandUp: boolean
  userToken: string
  name: string
}

export const handleUserHandUpStatus = ({ socket, isHandUp, userToken, name = '' }: RoomHandUpParams) => {
  const extra = {
    name,
  }

  const content = {
    sid: WS_EVENT_LIVE_SHOW.sid,
    ssid: WS_EVENT_LIVE_SHOW.handup,
    body: {
      uid: userToken,
      status: isHandUp ? HAND_UP_STATUS.ON : HAND_UP_STATUS.OFF,
      extra: JSON.stringify(extra),
    },
  }

  handleMessageEmit({ socket, content })
}
