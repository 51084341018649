import { ofType } from 'redux-observable'
import { mergeMap } from 'rxjs/operators'
import { EMPTY } from 'rxjs'

import { setHostDisconnectUser } from '@/redux/reducers/socket'

// Libs
import { handleUserConnectStatus } from '@/methods/message/handleUserConnectStatus'
import { handleUnSub } from '@/methods/message/webRTC'
import { getRoomSocketUsers } from '@/methods/message'

export const setHostDisconnectUserAction = ({ action$, state }: any) => {
  return action$.pipe(
    ofType(setHostDisconnectUser.type),
    mergeMap((action2: any) => {
      const { payload } = action2
      const socketData = state.value.socket
      const { socket, userToken, rtcData, hostData } = socketData

      handleUserConnectStatus({ socket, uid: payload.uid, isConnect: false, name: '' })

      handleUnSub({ socket, src: userToken, target: rtcData?.token || hostData?.token })
      getRoomSocketUsers({ socket })

      return EMPTY
    }),
  )
}
