import { createSlice } from '@reduxjs/toolkit'

// Constant
import { ROLE } from '@/constants/role'

interface MemberStateType {
  memberData: {
    memberInfoId: string
    nickName: string
    headshotUrl: string
    role: number
    socketToken: string
    refCode: string
    email: string
    roles: number[]
  }
}

const initialState: MemberStateType = {
  memberData: {
    memberInfoId: '',
    nickName: '',
    headshotUrl: '',
    role: ROLE.GUEST,
    socketToken: '',
    refCode: '',
    email: '',
    roles: [],
  },
}

export const memberSlice = createSlice({
  name: 'member',
  initialState,
  reducers: {
    setMemberData: (state, { payload }) => {
      state.memberData = { ...state.memberData, ...payload.memberData }
    },
    setMemberRole: (state, { payload }) => {
      state.memberData.role = payload.role
    },
  },
})

export const { setMemberData, setMemberRole } = memberSlice.actions

export default memberSlice.reducer
