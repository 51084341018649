import { merge } from 'rxjs'

// Actions
import { setHostDisconnectUserAction } from './actions/setHostDisconnectUserAction'
import { setRoomHandUpCloseAction } from './actions/setRoomHandUpCloseAction'
import { setRoomHandUpStatusAction } from './actions/setRoomHandUpStatusAction'
import { setHostConnectUserAction } from './actions/setHostConnectUserAction'
import { setUserOffByHostOutAction } from './actions/setUserOffByHostOutAction'

// Constants
// import { WS_EVENT_SYSTEM } from '@/constants/socketEvent'

const websocketEpic: any = (action$: any, state: any) => {
  return merge(
    setHostDisconnectUserAction({ action$, state }),
    setRoomHandUpCloseAction({ action$, state }),
    setRoomHandUpStatusAction({ action$, state }),
    setHostConnectUserAction({ action$, state }),
    setUserOffByHostOutAction({ action$, state }),
  )
}

export default websocketEpic
