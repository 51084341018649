import { ReactChild } from 'react'
import classnames from 'classnames/bind'
import { useRouter } from 'next/router'

// Libs
import handleRoutePushWithQuery from '@/methods/url/handleRoutePushWithQuery'
import handleLocationHrefDirect from '@/methods/url/handleLocationHrefDirect'
import { useAppSelector } from '@/redux/hooks'

// Components

//Constants
import { ROLE } from '@/constants/role'

// Styles
import styles from './style.module.scss'
import { ROUTE_PATH } from '@/constants/routePath'
import { ROOM_LIVE_TYPE } from '@/constants/liveRoom'

// Variables
const cx = classnames.bind(styles)

type LinkComponentType = {
  children?: ReactChild | ReactChild[]
  className?: string
  itemClassName?: string
  pathname?: string
  url?: string
  hasSearchParams?: boolean
  scroll?: boolean
  isOpen?: boolean
  isReplace?: boolean
  handleClick?: any
  locale?: string
  isMail?: boolean
  query?: object
}

// 分為 route 的 link 跟 url 的 link
// 如果有給 pathname 就視為 route 的 lnik
function LinkComponent(props: LinkComponentType) {
  const {
    children,
    className,
    itemClassName,
    pathname,
    url = '',
    hasSearchParams,
    scroll = true,
    isOpen,
    isReplace = false,
    handleClick,
    locale = '',
    isMail,
    query,
  } = props

  const router = useRouter()

  const isRoute = !!pathname

  const roomData = useAppSelector((state) => state.room?.roomData)
  const memberData = useAppSelector((state) => state.member?.memberData)

  const isHost = memberData?.memberInfoId === roomData?.memberInfoId || memberData?.role === ROLE.IT
  const isLive = roomData.liveType === ROOM_LIVE_TYPE.CURRENT

  const isLivePage = router?.pathname === ROUTE_PATH.LIVE_ROOM || router?.pathname === ROUTE_PATH.STRAWBERRY_LIVE_ROOM

  const handleRouteClick = async () => {
    handleClick && handleClick()

    // 如果在直播間，直播主點了連結都做成另開新頁
    if (pathname && isLivePage && isHost && isLive) {
      handleRoutePushWithQuery({ router, pathname, scroll, isOpen: true, locale, isReplace, query })
    } else if (router.pathname === pathname) {
      // 如果點當下 router 的 link 就重新整理，但要再觀察會不會有 useState 或 redux 資料要留著的情境 // http://tp-jira.tutorabc.com/browse/SHOP-208
      router.reload()
    } else if (pathname) {
      handleRoutePushWithQuery({ router, pathname, scroll, isOpen, locale, isReplace, query })
    }
  }

  const handleLinkClick = () => {
    handleClick && handleClick()

    if (url) return handleLocationHrefDirect({ router, url, hasSearchParams, isOpen })
  }

  return (
    <div className={cx('link', className)}>
      {!isMail && isRoute && (
        <div className={cx('link-item', itemClassName)} onClick={handleRouteClick}>
          <a className={cx('link-item-tag')} href={`${pathname}`} />
          {children}
        </div>
      )}

      {!isMail && !isRoute && (
        <div className={cx('link-item', itemClassName)} onClick={handleLinkClick}>
          <a className={cx('link-item-tag')} href={url} />

          {children}
        </div>
      )}

      {isMail && (
        <a className={cx('link-item', itemClassName)} href={`mailto:${url}`}>
          {children}
        </a>
      )}
    </div>
  )
}

export default LinkComponent
