// import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { isNull, isUndefined } from 'lodash'

// Libs
import { fetchSearchHotKey } from '@/api/fetchSearchHotKey'

// Constants
import { QUERY_KEY } from '@/constants/queryKey'

export interface IHotKeyData {
  hotKeyData: {
    keywords: IKeyword[]
  }
  keywordList: string[]
  filterKeywordList?: IKeyword[]
}
interface IKeyword {
  keyword: string
  keywordSource: string
}

const useSearchHotKey = ({ autoCompleteKeyword = '' }: { autoCompleteKeyword?: string }): IHotKeyData => {
  const { data: hotKeyData } = useQuery([QUERY_KEY.FETCH_SEARCH_HOT_KEY], fetchSearchHotKey)
  const keywordList =
    !isUndefined(hotKeyData?.keywords) && !isNull(hotKeyData.keywords)
      ? hotKeyData?.keywords.map((item: { keyword: string; keywordSource: string }) => item.keyword)
      : []

  // 把關鍵字出現在字串裡越前面的排序往前挪
  // 把關鍵字長度越短的排序往前挪
  const filterKeywordList =
    (autoCompleteKeyword as string).trim().length === 0
      ? []
      : (!isUndefined(hotKeyData?.keywords) &&
          !isNull(hotKeyData.keywords) &&
          hotKeyData?.keywords
            .filter((item: IKeyword) => (item.keyword as string).toLowerCase().includes((autoCompleteKeyword as string).toLowerCase()))
            .sort((a: IKeyword, b: IKeyword) => {
              const aIndex = a.keyword.indexOf(autoCompleteKeyword as string)
              const bIndex = b.keyword.indexOf(autoCompleteKeyword as string)
              if (aIndex === bIndex) {
                return a.keyword.length - b.keyword.length
              } else {
                return aIndex - bIndex
              }
            })
            .slice(0, 10)
            .sort((a: IKeyword, b: IKeyword) => a.keyword.length - b.keyword.length)) ||
        []

  return {
    hotKeyData,
    keywordList,
    filterKeywordList,
  }
}

export default useSearchHotKey
