import { createSlice } from '@reduxjs/toolkit'

// Constant

interface ISource {
  [key: string]: {
    count: number
  }
}

export interface IInitialState {
  product: ISource
}

const initialState: IInitialState = {
  product: {},
}

export const liveSlice = createSlice({
  name: 'live',
  initialState,
  reducers: {
    setLiveProductInfo: (state, { payload }) => {
      state.product = payload
    },
  },
})

export const { setLiveProductInfo } = liveSlice.actions

export default liveSlice.reducer
