import { useRouter } from 'next/router'

export const useIsServerSideShopApp = () => {
  const router = useRouter()
  const { query } = router

  const isServerSideShopApp = query?.mode === '1'

  return isServerSideShopApp
}
