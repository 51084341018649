import Service, { getCallApiFunction } from '../service'
// import denormalizer from './denormalizer'
// import normalizer from './normalizer'

class FetchMemberDataApi extends Service {
  constructor() {
    super()
    this.name = 'FETCH_MEMBER_DATA'

    this.config = {
      url: `/v1/member/profile`,
      method: 'GET',
    }
  }
}

export const fetchMemberData = () => getCallApiFunction(new FetchMemberDataApi())
