import UAParser from 'ua-parser-js'

const parser = new UAParser()

export const ua = parser.getResult()

const OS_NAME = {
  NOT_FOUND: 0,
  MAC_OR_IOS: 1,
  ANDROID: 2,
  WINDOW: 3,
}

const BROWSER_NAME = {
  NOT_FOUND: 0,
  CHROME: 1,
  FIREFOX: 2,
  IE: 3,
  SAFARI: 4,
}

const DEVICE_TYPE = {
  NOT_FOUND: 0,
  TABLET: 1,
  DESKTOP: 2,
}

const getOSName = () => {
  const osName = ua.os.name && ua.os.name.toLowerCase()
  if (osName) {
    if (osName.includes('mac') || osName.includes('ios')) {
      return OS_NAME.MAC_OR_IOS
    }
    if (osName.includes('android')) {
      return OS_NAME.ANDROID
    }
    if (osName.includes('window')) {
      return OS_NAME.WINDOW
    }
  }
  return String(OS_NAME.NOT_FOUND)
}

const getBrowserName = () => {
  const browserName = ua.browser.name && ua.browser.name.toLowerCase()
  if (browserName) {
    if (browserName.includes('chrome')) {
      return BROWSER_NAME.CHROME
    }
    if (browserName.includes('firefox')) {
      return BROWSER_NAME.FIREFOX
    }
    if (browserName.includes('ie') || browserName.includes('edge')) {
      return BROWSER_NAME.IE
    }
    if (browserName.includes('safari')) {
      return BROWSER_NAME.SAFARI
    }
  }
  return String(BROWSER_NAME.NOT_FOUND)
}

export const isSafari = (): boolean => {
  return getBrowserName() === BROWSER_NAME.SAFARI
}

const getDeviceType = () => {
  const deviceType = ua.device.type && ua.device.type.toLowerCase()
  if (deviceType) {
    if (deviceType.includes('tablet') || deviceType.includes('mobile') || deviceType.includes('cellphone')) {
      return DEVICE_TYPE.TABLET
    } else if (deviceType.includes('desktop')) {
      return DEVICE_TYPE.DESKTOP
    }
  }
  return String(DEVICE_TYPE.NOT_FOUND)
}

export const getUACode = () => `${+getOSName()}:${ua.os.version}:${+getBrowserName()}:${ua.browser.version}:${+getDeviceType()}`
