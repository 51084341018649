import Service, { getCallApiFunction } from '../service'
// import denormalizer from './denormalizer'
// import normalizer from './normalizer'

export type DataType = {
  uids?: string[]
  roomId?: string
}

class FetchRoomUserListByUidApi extends Service {
  constructor(data: DataType) {
    super()
    this.name = 'FETCH_ROOM_USER_LIST_BY_UID'

    this.config = {
      url: `/v1/room/list/member/uids`,
      method: 'POST',
      data,
    }
  }
}

export const fetchRoomUserListByUid = (data: DataType) => getCallApiFunction(new FetchRoomUserListByUidApi(data))
