type ParamsType = {
  accountId: string
  name: string
}

const denormalizer = ({ accountId, name }: ParamsType) => {
  return {
    straw_uid: accountId,
    straw_name: name,
  }
}

export default denormalizer
