import { ofType } from 'redux-observable'
import { mergeMap } from 'rxjs/operators'
import { EMPTY } from 'rxjs'

import { setRoomHandUpClose } from '@/redux/reducers/socket'
import { handleMessageEmit } from '@/methods/message/handleMessageEmit'

// Libs

// Constants
import { WS_EVENT_ROOM, HAND_UP_STATUS, WS_EVENT_LIVE_SHOW } from '@/constants/socketEvent'

export const setRoomHandUpCloseAction = ({ action$, state }: any) => {
  return action$.pipe(
    ofType(setRoomHandUpClose.type),
    mergeMap(() => {
      const socketData = state.value.socket
      const { socket } = socketData

      const content = {
        sid: WS_EVENT_ROOM.sid,
        ssid: WS_EVENT_ROOM.state,
        body: [
          {
            key: 'canHandUp',
            value: HAND_UP_STATUS.OFF,
          },
        ],
      }
      const resetContent = {
        sid: WS_EVENT_LIVE_SHOW.sid,
        ssid: WS_EVENT_LIVE_SHOW.reset,
      }
      handleMessageEmit({ socket, content: resetContent })
      handleMessageEmit({ socket, content })

      return EMPTY
    }),
  )
}
