import Service, { getCallApiFunction } from '../service'
// import denormalizer from './denormalizer'
// import normalizer from './normalizer'

export interface IPlatformAnnouncementsData {
  title: string
  img_url: string
  img_location: number
  location: string
  platform: number
}

class FetchPlatformAnnouncementsApi extends Service {
  constructor(params: any) {
    super()
    this.name = 'FETCH_PLATFORM_ANNOUCEMENTS'

    this.config = {
      url: `/v1/platform/announcements`,
      method: 'GET',
      data: { ...params },
    }
  }
}

export const fetchPlatformAnnouncements = (params: any): IPlatformAnnouncementsData[] => getCallApiFunction(new FetchPlatformAnnouncementsApi(params))
