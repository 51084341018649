import { ISearchProductInfo } from '@/hooks/useSearchData'

export const enum SEARCH_TYPE {
  ALL = 'all',
  LIVE = 'live',
  PRODUCT = 'product',
  MERCHANT = 'merchant',
}

export const SEARCH_MORE_TYPE = [
  {
    tabKey: 'LIVE',
    label: 'Common_Host',
    value: SEARCH_TYPE.LIVE,
  },
  {
    tabKey: 'MERCHANT',
    label: 'Search_See_More_Label_Merchant',
    value: SEARCH_TYPE.MERCHANT,
  },
]

type TProductFilterTabData = {
  label: string
  sort: (data: ISearchProductInfo[]) => ISearchProductInfo[]
}

export const SEARCH_PRODUCT_FILTER_TAB_DATA: TProductFilterTabData[] = [
  {
    label: 'Search_Product_Sort_Most_Hot_Sales',
    sort: (data: ISearchProductInfo[]) => {
      const newData = [...data]
      return newData.sort((a, b) => b.saleQuantity - a.saleQuantity)
    },
  },
  {
    label: 'Search_Product_Sort_Most_Cheap',
    sort: (data: ISearchProductInfo[]) => {
      const newData = [...data]
      return newData.sort((a, b) => {
        return a.minPrice - b.minPrice
      })
    },
  },
  {
    label: 'Search_Product_Sort_Most_New',
    sort: (data: ISearchProductInfo[]) => {
      const newData = [...data]
      return newData.sort((a, b) => {
        return new Date(b.onShelfDate).getTime() - new Date(a.onShelfDate).getTime()
      })
    },
  },
]

export const searchTabsData = [
  {
    tabKey: 'ALL',
    label: 'Search_Result_Tab_Label_All',
    value: SEARCH_TYPE.ALL,
  },
  {
    tabKey: 'ROOM',
    label: 'Common_Tab_Live',
    value: SEARCH_TYPE.LIVE,
  },
  {
    tabKey: 'PRODUCT',
    label: 'Common_Tab_Product',
    value: SEARCH_TYPE.PRODUCT,
  },
]
