export const CartAction = {
  ADD: 0,
  DELETE: 1,
  UPDATE: 2,
  CLEAR_EXPIRED: 3,
}

export const CartStatus = {
  UNCONFIRMATION: 'unconfirmation',
  CONFIRMATION: 'confirmation',
  SUCCESS: 'success',
  FAILURE: 'failure',
  CREATE_ORDER: 'create-order',
}
