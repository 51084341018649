import Service, { getCallApiFunction } from '../service'
// import denormalizer from './denormalizer'
// import normalizer from './normalizer'

export type DataType = {
  roomId: string
  logList: {
    data: string
    level: string
    ts: number
  }[]
}

class SetDebugReportApi extends Service {
  constructor(data: DataType) {
    super()
    this.name = 'SET_DEBUG_REPORT'

    this.config = {
      url: `/v1/report/debug-report`,
      method: 'POST',
      data,
    }
  }
}

export const setDebugReport = (data: DataType) => getCallApiFunction(new SetDebugReportApi(data))
