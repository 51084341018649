export interface SearchParams {
  fromWhere?: string
  media?: string
  cct?: string
  m?: string
  s?: string
  jobId?: string
  path?: string
  testMode?: string
}

export const utmSearchParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']

const getSearchParams = (url?: string) => {
  if (!process.browser) return

  const search = url ? new URL(url).search : window.location.search
  const searchParams = new URLSearchParams(search)

  // 統一把 search 的 key 轉成小寫，value 則保留原樣
  const newSearchParamsArray = Object.entries(Object.fromEntries(searchParams)).map(([key, value]) => [[key.toLowerCase()], value])
  const newSearchParams = Object.fromEntries(newSearchParamsArray)

  // TutorABC網域間傳遞參數
  // fromWhere(媒體碼)
  // media(廣告參數)
  // cct(廣告參數)
  // m(會員推薦碼)
  // s(員工推薦碼)

  const fromWhere = newSearchParams?.fromwhere
  const media = newSearchParams?.media
  const cct = newSearchParams?.cct
  const m = newSearchParams?.m
  const s = newSearchParams?.s
  const jobId = newSearchParams?.jobid
  const path = newSearchParams?.path
  // const cache = newSearchParams?.cache // 給 API 的參數，Jim 說不要透傳
  const testMode = newSearchParams?.testmode // testmode = 1 進入測試模式
  const country_code = newSearchParams?.country_code
  const locale = newSearchParams?.locale
  const mode = newSearchParams?.mode
  // const utm_source = newSearchParams?.utm_source // utm 相關參數，參考其他網站先不透傳
  // const utm_medium = newSearchParams?.utm_medium // utm 相關參數，參考其他網站先不透傳
  // const utm_campaign = newSearchParams?.utm_campaign // utm 相關參數，參考其他網站先不透傳
  // const utm_term = newSearchParams?.utm_term // utm 相關參數，參考其他網站先不透傳
  // const utm_content = newSearchParams?.utm_content // utm 相關參數，參考其他網站先不透傳

  const finalSearchParams = {
    fromWhere,
    media,
    cct,
    m,
    s,
    jobId,
    path,
    // cache,
    testMode,
    country_code,
    locale,
    mode,
    // utm_source,
    // utm_medium,
    // utm_campaign,
    // utm_term,
    // utm_content,
  }

  Object.keys(finalSearchParams).forEach((key) => {
    if (!finalSearchParams[key as keyof typeof finalSearchParams]) delete finalSearchParams[key as keyof typeof finalSearchParams]
  })

  return finalSearchParams
}

export default getSearchParams
